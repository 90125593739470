import {Box, Typography, Button, IconButton} from '@mui/material';
import {useState} from 'react';
import HeaderContainer from '../components/common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BottomBar from '../components/common/BottomBar';
import {useNavigate, Link} from 'react-router-dom';
import HelpOutline from '@mui/icons-material/HelpOutline';
import Modal from '../components/common/Modal';
import ModalHeader from '../components/common/ModalHeader';
import LaunchIcon from '@mui/icons-material/Launch';
import BibleReadingPlan from '../components/modules/ReadingPlan/BibleReadingPlan';
import OutsideReadingPlan from '../components/modules/ReadingPlan/OutsideReadingPlan';
import { margin } from '@mui/system';

export default function ReadingPlan() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [isPlanUpdated, setIsPlanUpdated] = useState(false);

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Box my={1} px={3}>
        <HeaderContainer pt={1} mt={2}>
          <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => navigate(-1)} color="white" />
        </HeaderContainer>
        <Box mt={3}>
          <Box mb={2}>
            <Typography sx={{fontSize: '11px'}}>SET OR EDIT YOUR GROUP</Typography>
            <Typography sx={{fontSize: '28px', fontWeight: 'bold'}}>Reading Plan</Typography>
            <IconButton
              style={{
                marginTop: '28px',
                width: '28px',
                height: '28px',
                cursor: 'pointer'
              }}
              onClick={() => setModalOpen(true)}
            >
              <HelpOutline style={{color: 'white'}} />
            </IconButton>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Button
              sx={{
                backgroundColor: selected ? 'black' : 'white',
                color: selected ? 'white' : 'black',
                width: '45%',
                borderRadius: '25px',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                height: '2rem !important',
                paddingX: '1rem',
                '&:hover': {
                  backgroundColor: selected ? 'black' : 'white'
                },
                '&:focus': {
                  backgroundColor: selected ? 'black' : 'white',
                  color: selected ? 'white' : 'black'
                }
              }}
              onClick={() => {
                setSelected(false);
              }}
            >
              BIBLE READING PLAN
            </Button>
            <Button
              sx={{
                backgroundColor: selected ? 'white' : 'black',
                color: selected ? 'black' : 'white',
                width: '45%',
                borderRadius: '25px',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                height: '2rem !important',
                paddingX: '1rem',
                '&:hover': {
                  backgroundColor: selected ? 'white' : 'black'
                },
                '&:focus': {
                  backgroundColor: selected ? 'white' : 'black',
                  color: selected ? 'black' : 'white'
                }
              }}
              onClick={() => {
                if (isPlanUpdated) {
                  setSelected(true);
                } else {
                  if(!setModalOpen2) {
                    setModalOpen2(true);
                  }
                }
              }}
            >
              OUTSIDE READING
            </Button>
          </Box>
          {!selected ? <BibleReadingPlan setSelected={setSelected} setIsPlanUpdated={setIsPlanUpdated} isPlanUpdated={isPlanUpdated} /> : <OutsideReadingPlan />}
        </Box>
      </Box>
      <BottomBar
        buttonInputs={[
          {text: 'Home', onClick: () => navigate('/leader')},
          {text: '', onClick: () => {}, isShow: false},
          {
            text: 'Reading',
            onClick: () => navigate('/leader/meetings'),
            isShow: false
          },
          {text: 'Settings', onClick: () => {}}
        ]}
      />
      <Modal
        open={modalOpen2}
        setOpen={setModalOpen2}
        title="Bible Plan"
        buttons={[
          {name: 'No', onClick: () => setModalOpen2(false)},
          {
            name: 'Yes',
            onClick: () => {
              setModalOpen2(false);
              setSelected(true);
            }
          }
        ]}
      >
        <Typography sx={{color: 'white'}}>Are you sure you want to go on outside reading plan before updating bible reading plan. Do want to continue?</Typography>
      </Modal>

      <Modal open={modalOpen} setOpen={setModalOpen} buttons={[{name: 'Done', onClick: () => setModalOpen(false)}]} style={{height: '100%'}} backgroundColor="white">
        <ModalHeader title="Reading Plan Set Up" />
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          BIBLE READING PLAN
        </Typography>
        <Typography mt={1} color="#000">
          The app automatically calculates the Bible reading plan based on the number of weeks that your group will meet (from 42 - 48 weeks). However, you can modify that plan for expected or unexpected skipped weeks (think Christmas). You can enter these skips ahead of time or when the need to happen. Skips have two options:
        </Typography>
        <ul>
          <li>
            <Typography mt={1} color="#000">
              • Double Up: This is the norm. Disciples will keep reading both weeks and you will cover two weeks of reading at your next meeting.
            </Typography>
          </li>
          <li>
            <Typography mt={1} color="#000">
              • Truly Skip: This option requires no reading on the skipped week but extends your End Date of Discipleship pushing all reading out by one week.
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          OUTSIDE READING
        </Typography>
        <Typography mt={1} color="#000">
          Outside reading connects the Biblical truths they are learning with real life choices. circumstances and decisions. You can preload the SD standard plan and then rearrange, add books (be careful!) and adjust reading weeks. Here's a few tips.
        </Typography>
        <ul>
          <li>
            <Typography mt={1} color="#000">
              • All the SD Library Books tested for you year of discipleship are in dropdown for each book.
            </Typography>
          </li>
          <li>
            <Typography mt={1} color="#000">
              • The weeks to read are selected via a dropdown ranging from I to 16 (most books are read in 3-6 weeks). Our tested values preload based on selection of book.
            </Typography>
          </li>
          <li>
            <Typography mt={1} color="#000">
              • The "Weeks Remaining" to read adjusts each time you change a "time to read" dropdown showing you how many weeks are still available for outside reading.
            </Typography>
          </li>
          <li>
            <Typography mt={1} color="#000">
              • For information on how to use a book, book pairings, etc. visit this link
             <Box ml={1} display="inline-flex" sx={{ verticalAlign: 'middle' }}>
              <Link to="https://sustainable-discipleship.com/sd-library" target="_blank">
                <LaunchIcon sx={{ color: 'black', mt: '1px', fontSize: '18px' }} />
              </Link>
            </Box>
            </Typography>
          </li>
        </ul>
      </Modal>
    </Box>
  );
}
