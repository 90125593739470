import React, {useState, useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Alert, Grid, Box, Link, Typography} from '@mui/material';
import TextInput from '../../common/TextInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormWrapper from '../../common/StyledFormWrapper';
import {useNavigate} from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';
import {CONSTANTS} from '../../../constants';
import {checkInviteUser, otpVerificationApi, registerInvitedUser, registerNonInvitedUser} from '../../../api';
import PasswordChecklist from 'react-password-checklist';
import {capitalizeWords, validateEmail} from '../../../utils';
import sdlogo from '../../../images/sdlogo.png';
import CheckBoxInput from '../../common/CheckBoxInput';
import ReCAPTCHA from 'react-google-recaptcha';
import useAuthStore from '../../../store/authStore';
import useAdminStore from '../../../store/adminStore';
import Modal from '../../common/Modal';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [inviteCode, setInviteCode] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [error, setError] = useState(null);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const debounceEmail = useDebounce(email, CONSTANTS.DEBOUNCE_TIMEOUT);
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState('');
  const [isConsent, setIsConsent] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('inviteCode');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isCaptchaVerified, setIsCaptchaVerfied] = useState(false);
  const {loginUser} = useAuthStore(state => state);
  const {setOrganizationSelected} = useAdminStore(state => state);
  const [modalOpen, setModalOpen] = useState(false);
  let captchaRef;

  const checkInvitedUser = async signUpCode => {
    try {
      setLoading(true);
      const response = await checkInviteUser(signUpCode);
      if (response?.data?.status === 200) {
        setUserRole(response?.data?.data[0]?.user_role);
        setEmail(response?.data?.data[0]?.email);
        setName(`${response?.data?.data[0]?.first_name || ''} ${response?.data?.data[0]?.last_name || ''}`);
      }
      if (response?.data?.data?.length === 0) {
        navigate('/signup');
        setInviteCode(null);
      }
      setLoading(false);
    } catch (error) {
      setInviteCode(null);
      navigate('/signup');
      setLoading(false);
    }
  };

  const handleInvitedRegister = async () => {
    try {
      const fullName = name.trim();
      const data = {
        first_name: fullName.split(' ')[0],
        last_name: fullName.split(' ')[1] || '',
        email: email.toLowerCase().trim(),
        password: password,
        consent_signed: isConsent
      };
      setLoading(true);
      const response = await registerInvitedUser(inviteCode, data);

      if (response?.data?.status === 200) {
        loginUser(response?.data?.data);
        const userData = response?.data?.data;
        if (userData?.organizations[0]?.id) {
          setOrganizationSelected(userData?.organizations[0]?.id || '');
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleNonInvitedRegister = async () => {
    try {
      const fullName = name.trim();
      const data = {
        first_name: fullName.split(' ')[0],
        last_name: fullName.split(' ')[1] || '',
        email: email.toLowerCase().trim(),
        password: password,
        consent_signed: isConsent,
        captcha_code: captchaToken
      };
      setLoading(true);
      const response = await registerNonInvitedUser(data);
      if (response?.data?.status === 200) {
        setIsOtpSent(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsOtpSent(false);
    }
  };

  const handleRegisteration = async () => {
    if (!name.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
      return;
    }
    if (!email || !password) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
      return;
    }
    if (!isValidPassword) {
      return;
    }
    if (!isConsent) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
      return;
    }
    if (inviteCode) {
      handleInvitedRegister();
    } else {
      handleNonInvitedRegister();
    }
  };

  const verifyOtpHandler = async () => {
    if (validateEmail(email) && isValidPassword && name && otp && otp.trim().length > 0) {
      try {
        const data = {
          email: email.toLowerCase().trim(),
          otp: otp
        };
        setLoading(true);
        const response = await otpVerificationApi(data);
        if (response?.data?.status === 200) {
          navigate('/login');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    }
  };

  const userTypeRole = role => {
    let namedRole = '';
    switch (role) {
      case 'ADMIN':
        namedRole = 'Administrator';
        break;
      case 'DISCIPLE':
        namedRole = 'Discipleship';
        break;
      case 'LEADER':
        namedRole = 'Leadership';
        break;
    }
    return namedRole;
  };

  const onCaptchaChange = value => {
    setError(null);
    setIsCaptchaVerfied(true);
    setCaptchaToken(value);
  };

  const handleCaptchaChange = () => {
    if (isCaptchaVerified) {
      captchaRef.reset();
      setCaptchaToken(null);
      setIsCaptchaVerfied(false);
    }
  };

  useEffect(() => {
    setInviteCode(code);
    if (code) {
      checkInvitedUser(code);
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <Box mt={3} px={3} overflow="auto">
        <img src={sdlogo} width="100" height="100" />
        <FormWrapper
          subHeadingText={{
            text: (
              <>
                Already Registered? <span style={{textDecoration: 'underline'}}>Log in here</span>
              </>
            ),
            onClick: () => navigate('/login')
          }}
          buttonInputs={[
            {
              text: isOtpSent ? 'Verify OTP' : 'Sign Up',
              onClick: isOtpSent ? verifyOtpHandler : handleRegisteration
            }
          ]}
          headingText={`Create Your ${userTypeRole(userRole)} Account`}
          loading={loading}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} marginBottom={1}>
              <TextInput
                autoComplete="off"
                label="Name"
                placeholder="Name"
                value={name}
                maxLength={CONSTANTS.MAX_GROUP_LENGTH}
                onChange={e => {
                  handleCaptchaChange();
                  setName(String(capitalizeWords(e.target.value)).replace(CONSTANTS.REGEX.NAME_REGEX, ''));
                  setError(null);
                }}
                labelVariant="body2"
                disabled={isOtpSent}
              />
            </Grid>
            <Grid item xs={12} marginBottom={1}>
              <TextInput
                disabled={inviteCode || isOtpSent ? true : false}
                value={email}
                placeholder="Email"
                autoComplete="off"
                label="Email"
                isAllowSpecialChar={true}
                onChange={e => {
                  handleCaptchaChange();
                  setEmail(String(e.target.value).replace(/\s+/g, ''));
                }}
                error={debounceEmail && !validateEmail(email)}
                helperText={debounceEmail && !validateEmail(email) ? CONSTANTS.MESSAGES.INVALID_EMAIL : ''}
                labelVariant="body2"
              />
            </Grid>
            <Grid item xs={12} marginBottom={1}>
              <div className="relative_container">
                <TextInput
                  autoComplete="off"
                  label="Password"
                  value={password}
                  placeholder="Password"
                  onChange={e => {
                    handleCaptchaChange();
                    setPassword(e.target.value);
                    setError(null);
                  }}
                  isAllowSpecialChar={true}
                  type={showPassword ? 'text' : 'password'}
                  labelVariant="body2"
                  disabled={isOtpSent}
                />
                {showPassword ? <VisibilityIcon onClick={() => setShowPassword(false)} className="eye_icon" /> : <VisibilityOffIcon onClick={() => setShowPassword(true)} className="eye_icon" />}
              </div>
              <PasswordChecklist
                rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
                minLength={8}
                value={password}
                validColor="#FFFFFF"
                style={{textAlign: 'start'}}
                messages={{
                  minLength: CONSTANTS.MESSAGES.PASSWORD_INFO.LENGTH,
                  specialChar: CONSTANTS.MESSAGES.PASSWORD_INFO.SPECIAL_CHARACTERS,
                  number: CONSTANTS.MESSAGES.PASSWORD_INFO.NUMBER,
                  capital: CONSTANTS.MESSAGES.PASSWORD_INFO.UPPERCASE,
                  lowercase: CONSTANTS.MESSAGES.PASSWORD_INFO.LOWERCASE
                }}
                onChange={isValid => setIsValidPassword(isValid)}
              />
            </Grid>

            {isOtpSent && (
              <Grid item xs={12} marginBottom={1}>
                <TextInput
                  value={otp}
                  placeholder="OTP"
                  autoComplete="off"
                  label="OTP"
                  onChange={e => {
                    setError(null);
                    setOtp(String(e.target.value).replace(!CONSTANTS.REGEX.ONLY_APHANUMERIC, ''));
                  }}
                  labelVariant="body2"
                  maxLength={4}
                  isAllowSpecialChar={true}
                />
              </Grid>
            )}
            {!code && (
              <Grid item xs={12}>
                <ReCAPTCHA
                  ref={r => {
                    if (r) {
                      return (captchaRef = r);
                    }
                  }}
                  className="captcha-item"
                  theme="dark"
                  sitekey={CONSTANTS.GCAPTCHA_CLIENT_KEY}
                  onChange={onCaptchaChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <CheckBoxInput
                checked={isConsent}
                onChange={() => {
                  setError(null);
                  setIsConsent(!isConsent);
                }}
                label={
                  <>
                    I agree to the{' '}
                    <Link
                      sx={{textDecoration: 'underline', cursor: 'pointer', color: 'inherit'}}
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      Terms and Privacy Policy
                    </Link>
                  </>
                }
              />
            </Grid>
            <Modal backgroundColor='white' color='black' open={modalOpen} setOpen={setModalOpen} title="Terms and Conditions for [Non-Profit Church's Mobile Web Application]" titleStyles={{fontWeight: 'bold', fontSize: '20px'}} buttons={[{name: 'Close', onClick: () => setModalOpen(false)}]}>
                <Typography variant="h6" component="h4" color="black" gutterBottom sx={{fontWeight: 'bold'}}>
                  Effective Date: {CONSTANTS.APPLICATION_REGISTRATION_DATE}
                </Typography>

                <Typography color="black" variant="h6" component="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                  1. Acceptance of Terms
                </Typography>
                <Typography color="black" paragraph>By accessing or using the [Non-Profit Church's Mobile Web Application] the "Sustainable Discipleship", you agree to comply with and be bound by these Terms and Conditions ("Terms"). If you do not agree with these Terms, please do not use the App.</Typography>

                <Typography color="black" variant="h6" component="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                  2. Eligibility
                </Typography>
                <Typography color="black" paragraph>The App is intended for use by individuals who are at least 13 years old. By using the App, you represent and warrant that you meet this age requirement.</Typography>

                <Typography color="black" variant="h6" component="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                  3. Privacy and Data Collection
                </Typography>
                <Typography color="black" paragraph>
                  <Typography color="black" component="span" sx={{fontWeight: 'bold'}}>
                    a. Anonymous Data Collection:{' '}
                  </Typography>
                  The App may collect anonymous data about your usage to help us improve the App. This data may include information such as your device type, operating system, and usage patterns. This data is collected anonymously and cannot be used to identify you personally.
                </Typography>
                <Typography color="black" paragraph>
                  <Typography color="black" component="span" sx={{fontWeight: 'bold'}}>
                    b. Personal Data Collection:{' '}
                  </Typography>
                  If you choose to provide personal information, such as your name, email address, or phone number, this data will be used by the organization for internal purposes. This may include promoting church events, providing personalized content, and helping to improve your experience with the App.
                </Typography>
                <Typography color="black" paragraph>
                  <Typography color="black" component="span" sx={{fontWeight: 'bold'}}>
                    c. Data Usage:{' '}
                  </Typography>
                  Personal information collected will not be sold or shared with third parties outside of our organization, except as required by law or to protect the rights, property, or safety of our users or others.
                </Typography>

                <Typography color="black" variant="h6" component="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                  4. Use of the App
                </Typography>
                <Typography color="black" paragraph>You agree to use the App only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the App.</Typography>

                <Typography color="black" variant="h6" component="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                  5. User Accounts
                </Typography>
                <Typography color="black" paragraph>To access certain features of the App, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</Typography>

                <Typography color="black" variant="h6" component="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                  6. Intellectual Property
                </Typography>
                <Typography color="black" paragraph>All content, trademarks, and data on the App, including but not limited to text, graphics, logos, icons, images, audio clips, and software, are the property of the [Non-Profit Church] or its content suppliers and protected by international intellectual property laws.</Typography>

                <Typography color="black" variant="h6" component="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                  7. Limitation of Liability
                </Typography>
                <Typography color="black" paragraph>To the fullest extent permitted by law, the [Non-Profit Church] shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or the inability to use the App or for the cost of procurement of substitute goods and services or resulting from any goods or services purchased or obtained or messages received or transactions entered into through the App or resulting from unauthorized access to or alteration of your transmissions or data.</Typography>

                <Typography color="black" variant="h6" component="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                  8. Termination
                </Typography>
                <Typography color="black" paragraph>We reserve the right to terminate or suspend your access to the App, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</Typography>

                <Typography color="black" variant="h6" component="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                  9. Changes to Terms
                </Typography>
                <Typography color="black" paragraph>We may revise these Terms from time to time without notice. By continuing to access or use the App after those revisions become effective, you agree to be bound by the revised Terms.</Typography>

                <Typography color="black" variant="h6" component="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                  10. Governing Law
                </Typography>
                <Typography color="black" paragraph>These Terms are governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.</Typography>

                <Typography color="black" variant="h6" component="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                  11. Contact Us
                </Typography>
                <Typography color="black" paragraph>
                  If you have any questions about these Terms, please contact us at <Link  sx={{textDecoration: 'underline', cursor: 'pointer', color: 'inherit'}} href="mailto:app@sustainable-discipleship.com">{CONSTANTS.CONTACT_URL}</Link>.
                </Typography>
            </Modal>
            <Grid item xs={12} sx={{height: '55px'}}>
              {error && <Alert severity="error">{error}</Alert>}
            </Grid>
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default Register;
