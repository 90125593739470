import React, {useEffect, useState} from 'react';
import {Box, useMediaQuery} from '@mui/material';
import PromotionsList from './common/PromotionsList';
import useAuthStore from '../../store/authStore';
import useAdminStore from '../../store/adminStore';
import AdminStructure from './common/AdminStructure';
import {getAdminData, getAdminLeadersGroupApi, getAdminOrgDisciplesApi} from '../../api';
import Loader from '../../components/common/Loader';

function Promotions() {
  const {user} = useAuthStore(state => state);
  const {organizationSelected} = useAdminStore(state => state);
  const [pageloading, setPageLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [disciples, setDisciples] = useState([]);
  const [loader, setLoader] = useState(false);

  const getAdminDataHandler = async () => {
    try {
      const res = await getAdminData(organizationSelected);
      if (res?.data?.status === 200) {
        setAdmins(res?.data?.data.filter(obj => obj.status === 'ACTIVE'));
      }
    } catch (error) {}
  };

  const getLeaderDataHandler = async () => {
    try {
      const res = await getAdminLeadersGroupApi(organizationSelected, 'user_role');
      if (res?.data?.status === 200) {
        setLeaders(res?.data?.data.filter(obj => obj.status === 'ACTIVE'));
      }
    } catch (error) {}
  };

  const getDiscipleDataHandler = async () => {
    try {
      const res = await getAdminOrgDisciplesApi(organizationSelected, 'disciple');
      if (res?.data?.status === 200) {
        setDisciples(res?.data?.data.filter(obj => obj.status === 'ACTIVE'));
      }
    } catch (error) {}
  };

  const getDataHandler = () => {
    const getAdminData = getAdminDataHandler();
    const getLeaderData = getLeaderDataHandler();
    const getDiscipleData = getDiscipleDataHandler();
    setPageLoading(true);
    Promise.all([getAdminData, getLeaderData, getDiscipleData])
      .then(results => {
        setPageLoading(false);
      })
      .catch(error => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getDataHandler();
  }, [organizationSelected]);

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <>
      <div
        style={{
          backgroundImage: 'none !important',
          backgroundColor: '#f9f9f9',
          height: '100vh',
          width: '100vw',
          overflowY: isSmallScreen ? 'auto' : 'hidden',
          overflowX: 'hidden'
        }}
      >
        <AdminStructure heading="Promotion">
          {pageloading ? (
            <Loader color="#9e0001" />
          ) : (
            <Box
              display={'flex'}
              // justifyContent={"space-between"}
              px={2}
              gap={2}
              height="82vh"
              sx={{
                flexDirection: {
                  md: 'row',
                  sm: 'column',
                  xs: 'column'
                },
                justifyContent: {
                  md: 'space-between'
                }
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  width: {
                    md: '50%',
                    xs: '100%',
                    sm: '100%'
                  },
                  marginBottom: {
                    xs: 2,
                    sm: 2,
                    md: 0
                  }
                }}
              >
                {loader ? (
                  <Loader color="#9e0001" />
                ) : (
                  <PromotionsList
                    user={user}
                    role="ADMIN"
                    title="Admins"
                    height="96%"
                    initialItems={admins}
                    showDeleteIcon
                    width="100%"
                    menuList={['ADMIN', 'LEADER']} // admin disable
                    getDataHandler={getDataHandler}
                    setLoader={setLoader}
                    organizationSelected={organizationSelected}
                  />
                )}
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                // pl={2}
                sx={{
                  width: {
                    md: '50%',
                    xs: '100%',
                    sm: '100%'
                  },
                  gap: {
                    xs: 2,
                    sm: 2,
                    md: 2
                  }
                }}
              >
                {loader ? (
                  <Loader color="#9e0001" />
                ) : (
                  <PromotionsList
                    role="LEADER"
                    title="Leaders"
                    initialItems={leaders}
                    height="auto"
                    showDeleteIcon
                    organizationSelected={organizationSelected}
                    // width="100%"
                    menuList={['ADMIN', 'LEADER']} // leader disable
                    getDataHandler={getDataHandler}
                    setLoader={setLoader}
                  />
                )}
                {loader ? (
                  <Loader color="#9e0001" />
                ) : (
                  <PromotionsList
                    role="DISCIPLE"
                    title="Disciples"
                    initialItems={disciples}
                    height="auto"
                    showDeleteIcon
                    organizationSelected={organizationSelected}
                    // width="100%"
                    menuList={['ADMIN', 'LEADER']}
                    getDataHandler={getDataHandler}
                    setLoader={setLoader}
                  />
                )}
              </Box>
              {/* </Box> */}
            </Box>
            // </Box>
          )}
        </AdminStructure>
      </div>
    </>
  );
}

export default Promotions;
