import React, {useState} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import RadioButtonGroup from '../../common/RadioButtonGroup';
import {Box, IconButton, Avatar, Grid, Alert} from '@mui/material';
import TextInput from '../../common/TextInput';
import {CONSTANTS} from '../../../constants';
import {capitalizeWords, isValidFile} from '../../../utils';
import toast from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import EditIcon from '@mui/icons-material/Edit';
const PageThree = ({groupData, setGroupData, handleSubmit, isPublicAccount}) => {
  const [error, setError] = useState(null);
  const [captchaToken, setCaptchaToken] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerfied] = useState(false);
  let captchaRef;

  const handleInputChange = e => {
    setError(null);
    handleCaptchaChange();
    setGroupData(prevData => ({
      ...prevData,
      name: capitalizeWords(e.target.value)
    }));
  };

  const handleContinue = e => {
    e.preventDefault();
    if (groupData.gender.length <= 0 || groupData.level.length <= 0 || String(groupData?.name).trim().length === 0 || (isPublicAccount && !isCaptchaVerified)) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    } else {
      handleSubmit(captchaToken);
    }
  };

  const imageChangeHandler = e => {
    handleCaptchaChange();
    const file = e.target.files[0];
    if (file.size >= CONSTANTS.MAX_IMAGE_FILE_SIZE) {
      toast.error(CONSTANTS.MESSAGES.FILE_LARGE_IMAGE);
      return;
    }
    if (isValidFile('image', file)) {
      setGroupData(prevData => ({...prevData, avatar: e.target.files[0]}));
    } else {
      toast.error(CONSTANTS.MESSAGES.IMAGE_ALLOWED_ERROR);
      return;
    }
  };

  const handleRadioChange = (fieldName, value) => {
    handleCaptchaChange();
    setError(null);
    setGroupData(prevData => ({
      ...prevData,
      [fieldName]: value
    }));
  };

  const onCaptchaChange = value => {
    setError(null);
    setIsCaptchaVerfied(true);
    setCaptchaToken(value);
  };
  const handleCaptchaChange = () => {
    if (isCaptchaVerified) {
      captchaRef.reset();
      setIsCaptchaVerfied(false);
    }
  };

  return (
    <FormWrapper buttonInputs={[{text: "Let's go", onClick: handleContinue}]} headingText="Build Your Group">
      <IconButton>
        <label style={{display: 'flex', cursor: 'pointer'}} htmlFor="contained-button-file">
          <Avatar
            src={groupData?.avatar ? URL.createObjectURL(groupData?.avatar) : ''}
            style={{
              margin: '10px',
              width: '60px',
              height: '60px'
            }}
          />
          <EditIcon
                      style={{
                        color: 'black',
                        position: 'absolute',
                        bottom: 20,
                        right: 19,
                        transform: 'translate(50%, 50%)',
                        zIndex: 1,
                        backgroundColor: 'whitesmoke',
                        borderRadius: '50%',
                        padding: '5px'
                      }}
                    />
          <input onChange={imageChangeHandler} style={{display: 'none'}} accept="image/*" id="contained-button-file" type="file" />
        </label>
      </IconButton>
      <TextInput value={groupData?.name} label="Group Name" placeholder="Group Name" onChange={handleInputChange} autoComplete="off" labelVariant="body2" maxLength={CONSTANTS.MAX_GROUP_LENGTH} />
      <Box mt={1}>
        <RadioButtonGroup label="WHAT YEAR ARE YOU LEADING" options={['D1', 'D2', 'D3']} selectedOption={groupData.level || null} onChange={value => handleRadioChange('level', value)} />
      </Box>
      <Box my={1}>
        <RadioButtonGroup label="GENDERED OR CO-ED" options={['Co-Ed', 'All Guys', 'All Girls']} selectedOption={groupData.gender || null} onChange={value => handleRadioChange('gender', value)} />
      </Box>
      {isPublicAccount && (
        <Grid item xs={12} mb={2}>
          <ReCAPTCHA
            ref={r => {
              if (r) {
                return (captchaRef = r);
              }
            }}
            className="captcha-item"
            theme="dark"
            sitekey={CONSTANTS.GCAPTCHA_CLIENT_KEY}
            onChange={onCaptchaChange}
          />
        </Grid>
      )}
      <Box height={50}>{error && <Alert severity="error">{error}</Alert>}</Box>
    </FormWrapper>
  );
};

export default PageThree;