import React from 'react';
import {Typography, Box} from '@mui/material';
import CustomButton from './Button';

const FormWrapper = ({children, headingTextColor = 'white', subHeadingText = {text: null, onClick: () => {}}, headingText = '', buttonInputs = [], loading}) => {
  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%" paddingLeft="0" paddingRight="0">
      <form autoComplete="off" autoSave="off">
        <Box mt={1}>
          <Typography variant="h1" fontSize={30} mb={1} color={headingTextColor}>
            {headingText}
          </Typography>
          {subHeadingText.text && (
            <Typography variant="body2" onClick={subHeadingText.onClick} style={{cursor: 'pointer', paddingBottom: 10}}>
              {subHeadingText.text}
            </Typography>
          )}
        </Box>
        <Box overflow="hidden" mt={2}>
          {children}
        </Box>
        {buttonInputs.map((button, index) => {
          return <CustomButton visible={button?.visible} disabled={loading} type={button?.type} key={index} text={button?.text} onClick={button.onClick} />;
        })}
      </form>
    </Box>
  );
};

export default FormWrapper;
