import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import sdlogo from '../../../images/sdlogo.png';
import {Link, useNavigate} from 'react-router-dom';

const GetStartedZero = ({setPage}) => {
  const heading1 = 'Ready to get started making disciples? Need a simple "how to" method proven in the real world.';
  const heading2 = 'Set up your d-group in under ten minutes, supercharge your disciple making, and change the world!';

  return (
    <Box mx={3} my={5}>
      <img src={sdlogo} width="80" height="80" />
      <Typography mt={2} variant="h3" fontWeight="medium">
        Let's Make Disciples!
      </Typography>
      <Typography variant="h6" py={2}>
        {heading1}
      </Typography>
      <Typography variant="h6" py={2} style={{marginBottom: "20px"}}>
        {heading2}
      </Typography>

      <Button type="submit" fullWidth onClick={() => setPage(2)} variant="contained" color="secondary" style={{fontWeight: 400}}>
        SEE HOW IT WORKS
      </Button>
      <Typography my={2}>
        Already Registered?{' '}
        <Link to="/login" className="anchor-link">
          Log in here.
        </Link>
      </Typography>
    </Box>
  );
};

export default GetStartedZero;
