import {createTheme} from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#fd4958'
    },
    error: {
      main: '#fff'
    },
    secondary: {
      main: '#02140A'
    }
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h3: {
      fontSize: '2rem',
      fontWeight: 900,
      color: '#ffffff'
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    h5: {
      fontSize: '1.3rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    body1: {
      fontSize: '.9rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    body2: {
      fontSize: '0.9rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    body3: {
      fontSize: '0.8rem',
      fontWeight: 200,
      color: '#ffffff'
    },
    body4: {
      fontSize: '2rem',
      fontWeight: 800,
      color: '#ffffff'
    },
    subtitle2: {
      fontSize: '0.6rem',
      fontWeight: 400,
      color: '#ffffff'
    },
    button: {
      fontSize: '1rem',
      // fontWeight: 400,
      color: '#ffffff'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '10px',
          fontWeight: '600',
          width: '100%',
          '@media (max-width: 600px)': {
            // Adjust the max-width according to your requirements
            height: '3.2rem'
          },
          '@media (min-width: 601px)': {
            // Set default height for larger screens
            height: '2.8rem'
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'rgba(0, 0, 0, 0.8) !important',
          backdropFilter: 'blur(5px)',
          border: 'none',
          boxShadow: 'none',
          width: '250px',
          '& .MuiListItemText-primary': {
            fontSize: '1.2rem'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: '1.2rem'
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          backgroundColor: 'rgba(255,255,255,0.5) !important',
          '& .MuiAlert-message': {
            fontSize: '0.8rem',
            fontWeight: '300',
            color: '#fff'
          },
          '& .MuiAlert-action': {
            fontSize: '0.8rem',
            fontWeight: '300',
            color: '#fff'
          },

          '& .MuiAlert-icon': {
            fontSize: '1.2rem',
            fontWeight: '600',
            color: '#fff'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.MuiSelect-select': {
            outline: 'none',
            border: 'none',
            textAlign: 'left'
          },
          '&. MuiTypography-root': {
            fontSize: '1.2rem',
            fontWeight: '600',
            color: '#fff'
          }
        },
        root: {
          color: 'white',
          border: 'none',
          borderRadius: '16px',
          background: 'rgba(255,255,255,0.5) !important'
        },
        icon: {
          color: 'white'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.1rem',
          color: '#ffffff'
        },
        '& .Mui focused': {
          color: '#fff'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.1rem',
          color: '#ffffff',
          textAlign: 'left'
        }
      }
    },
    MuiRadioGroup: {
      styleOverrides: {
        root: {
          color: '#ffffff'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderRadius: '12px',
          '& .MuiTypography-root': {
            color: '#fd4958',
            fontSize: '1.4rem'
          },
          '&:first-of-type': {
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
          width: '100%',
          '& .MuiInputBase-input:not(.text-black)': {
            padding: '10px !important',
            borderRadius: '12px !important',
            backgroundColor: 'rgba(255,255,255,0.5) !important',
            color: 'white',
            fontSize: '1.2rem !important'
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none !important'
            }
          },
          '& .MuiInputBase-input:focus': {
            backgroundColor: 'rgba(255,255,255,0.5) !important'
          },
          '& .MuiInputBase-input::placeholder': {
            color: 'white !important',
            fontSize: '1rem !important'
          },
          '& .MuiInputBase-root.Mui-error': {
            border: 'none !important'
          },
          '& .MuiFormHelperText-root.Mui-error': {
            color: '#fff !important'
          },
          '& .MuiInputLabel-root': {
            fontSize: '1.2rem !important'
          }
        }
      }
    }
  }
});

export default theme;
