import {useState} from 'react';
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
// import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import 'moment/locale/en-gb';

const TimePickerComponent = ({label, value, onChange}) => {
  const [open, setOpen] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
      <DemoContainer sx={{textAlign: 'left'}} components={['MobileTimePicker']}>
        <DemoItem label={label}>
          <MobileTimePicker
            open={open}
            onClose={() => setOpen(false)}
            slotProps={{
              textField: {
                onClick: () => setOpen(true)
              }
            }}
            sx={{svg: {color: 'white'}, color: 'black'}}
            className="timePicker"
            value={value}
            onChange={onChange}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default TimePickerComponent;
