import React from 'react';
import sdlogo from '../../../images/sdlogo.png';
import {Box, Button, Typography} from '@mui/material';

const GetStartedOne = ({setPage}) => {
  const text2 = 'The SD Method is being used across the US, on six continents, and in eight denominations to achieve 95% success in disciple making.';
  const text3 = 'That means 95% get in a group, finish the process, and go on to make disciples.';
  return (
    <Box mx={3} my={5}>
      <img src={sdlogo} width="80" height="80" />
      <Typography mt={2} variant="h3" fontWeight="medium">
        A Simple Repeatable Method
      </Typography>
      <Typography mb={2} >HOW IT WORKS &bull; 1 OF 5</Typography>
      <Typography variant="h6" py={2}>
        <strong>Let's Make Disciples</strong> is powered by the Sustainable Discipleship Method. The method is a proven "how to actually do this" - not more theory.
      </Typography>
      <Typography variant="h6" py={2}>
        {text2}
      </Typography>
      <Typography variant="h6" py={2} style={{marginBottom: "20px"}}>
        {text3}
      </Typography>
      <Button type="submit" fullWidth onClick={() => setPage(3)} variant="contained" color="secondary" style={{fontWeight: 400, marginBottom: '40px'}}>
        WHAT IS THE METHOD?
      </Button>
    </Box>
  );
};

export default GetStartedOne;
