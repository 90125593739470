import React, {useEffect, useState} from 'react';
import {AccordionSummary, AccordionDetails, Typography, Grid, Alert, Chip, Accordion} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextInput from './TextInput';
import {capitalizeWords, validateEmail} from '../../utils';
import useDebounce from '../../hooks/useDebounce';
import {CONSTANTS} from '../../constants/index';
import {deleteInvitationOfUserApi} from '../../api';
import Modal from './Modal';

function AccordianForm({formDataList, setFormDataList, setData, data, setEmailError, setNameError, setPhoneError, nameError, emailError, setError, error}) {
  const debounceEmail = useDebounce(data?.email, CONSTANTS.DEBOUNCE_TIMEOUT);
  const [modalOpen, setModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const handleChangeEmail = e => {
    setError(null);
    setData(prev => {
      return {
        ...prev,
        email: String(e.target.value).replace(/\s+/g, '')
      };
    });
    setEmailError('');
  };

  const handleChangeName = e => {
    setError(null);
    setNameError('');
    setData(prev => {
      return {
        ...prev,
        name: String(capitalizeWords(e.target.value)).replace(CONSTANTS.REGEX.NAME_REGEX, '')
      };
    });
  };

  const handleRemoveInvitation = async id => {
    try {
      const response = await deleteInvitationOfUserApi(id);
      if (response?.data?.status === 200) {
        setFormDataList(formDataList?.filter(user => user?.user_id !== id));
        setModalOpen(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    return () => {
      setData({
        name: '',
        email: ''
      });
    };
  }, []);
  return (
    <>
      <div style={{maxHeight: '50vh', overflowY: 'auto', paddingRight: '8px'}}>
        {formDataList.map((formData, index) => (
          <Accordion key={index} TransitionProps={{unmountOnExit: true}} style={{marginTop: '8px'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{fontWeight: 'bold'}}> {formData.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}
              >
                <Chip
                  size="lg"
                  variant="solid"
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    backgroundColor: '#D52222',
                    '&:hover': {
                      backgroundColor: '#D52222'
                    }
                  }}
                  onClick={() => {
                    setUserId(formData?.user_id);
                    setModalOpen(true);
                  }}
                  label="Remove this invitation"
                />
                <span>Name : {formData.name}</span>
                <span>Email : {formData.email}</span>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
        <div>
          <TextInput placeholder="Name" value={data?.name} onChange={handleChangeName} autoComplete="off" autoFocus={true} maxLength={CONSTANTS.MAX_GROUP_LENGTH} error={!!nameError} helperText={nameError} labelVariant="body2" sx={{marginBottom: '5px'}} />
          <TextInput placeholder="Email" value={data?.email} onChange={handleChangeEmail} autoComplete="off" margin="normal" isAllowSpecialChar={true} labelVariant="body2" error={(debounceEmail && !validateEmail(debounceEmail)) || emailError} helperText={(debounceEmail && !validateEmail(debounceEmail)) || emailError ? CONSTANTS.MESSAGES.INVALID_EMAIL : ''} />
        </div>

        <Grid item xs={12} sx={{height: '50px'}}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
        <Modal
          open={modalOpen}
          setOpen={setModalOpen}
          title="Remove user invitation"
          buttons={[
            {name: 'Close', onClick: () => setModalOpen(false)},
            {name: 'Remove', onClick: () => handleRemoveInvitation(userId)}
          ]}
        >
          <Typography sx={{color: 'white'}}>Are you sure you want to remove the user invitation?</Typography>
        </Modal>
      </div>
    </>
  );
}

export default AccordianForm;
