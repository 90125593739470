import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import CustomButton from './Button';

const Modal = ({children, open, setOpen, title, buttons, backgroundColor = '#9e0001', color = 'white', height, titleStyles}) => {
  return (
    <Dialog
      open={open}
      onClose={setOpen}
      PaperProps={{
        sx: {
          backgroundColor,
          color,
          padding: 1,
          overflow: 'hidden',
          width: '100vw',
          ...(height && {height: height})
        }
      }}
    >
      <DialogTitle sx={{backgroundColor, color, padding: '10px', ...titleStyles }}>{title}</DialogTitle>

      <DialogContent sx={{backgroundColor, color, padding: '10px'}}>{children}</DialogContent>

      <DialogActions>
        {buttons?.map((button, index) => {
          return <CustomButton type="outlined" key={index} onClick={button.onClick} text={button?.name} loading={button.loading} disabled={button.disabled} />;
        })}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
