import React, {useState} from 'react';
import {Box, CssBaseline} from '@mui/material';
import BottomBar from '../components/common/BottomBar';
import Home from '../components/modules/SuperAdmin/Home';
import {CONSTANTS} from '../constants';
import {isSpecialCharacterIncludes, validateEmail} from '../utils';
import CreateOrganisation from '../components/modules/SuperAdmin/CreateOrganisation';
import {addOrganization, inviteUser} from '../api';
import HeaderContainer from '../components/common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InviteUser from '../components/modules/InviteUser';

const SuperAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [orgError, setOrgError] = useState(null);
  const [formDataList, setFormDataList] = useState([]);
  const [adminData, setAdminData] = useState({name: '', email: ''});
  const [currentPage, setCurrentPage] = useState(0);
  const [orgName, setOrgName] = useState('');
  const [orgId, setOrgId] = useState();
  const [error, setError] = useState(null);

  const createNewOrg = async e => {
    e.preventDefault();
    if (isSpecialCharacterIncludes(orgName)) {
      return;
    }
    if (orgName?.trim()?.length > 0) {
      const data = {
        name: orgName,
        status: 'ACTIVE'
      };
      try {
        setLoading(true);
        const response = await addOrganization(data);
        if (response?.data?.status === 200) {
          setOrgId(response?.data?.data?.id);
          setFormDataList([]);
          setCurrentPage(2);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setOrgError(CONSTANTS.MESSAGES.ORGANIZATION_REQUIRED);
    }
  };
  const inviteAdmin = async (user, type) => {
    try {
      const nameParts = user.name?.split(' ');
      const first_name = nameParts[0];
      const last_name = nameParts[1] || '';
      const data = {
        first_name,
        last_name,
        email: user.email,
        organization_ids: [orgId]
      };
      setLoading(true);
      const response = await inviteUser(data);
      if (response?.data?.status === 200) {
        const userId = response?.data?.data?.id;
        if (type === 'continue') {
          setFormDataList([]);
          setCurrentPage(0);
        } else {
          setFormDataList(() => [...formDataList, {...adminData, user_id: userId}]);
        }
        setAdminData({
          name: '',
          email: ''
          // mobile_number: "",
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const onAddAdmin = async () => {
    if (!adminData.email && !adminData.name) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    }
    if (!adminData.email && adminData.name.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    }
    if (adminData.email && !adminData.name.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    }
    if (!validateEmail(adminData.email)) {
      return;
    }
    if (adminData.name.trim() && adminData.email.trim()) {
      inviteAdmin(adminData, 'add');
    }
  };

  const continueHandler = async () => {
    if (!adminData.email && !adminData.name && formDataList.length === 0) {
      setError(CONSTANTS.MESSAGES.ADD_ATLEAST_ADMIN);
    } else if (!adminData.email && adminData.name.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    } else if (adminData.email.trim() && !adminData.name.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    } else {
      if (validateEmail(adminData.email) && adminData.name.trim()) {
        inviteAdmin(adminData, 'continue');
      } else {
        if (formDataList.length > 0) {
          setFormDataList([]);
          setAdminData({name: '', email: ''});
          setCurrentPage(0);
        }
      }
    }
  };

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <CssBaseline />
      {currentPage === 0 && <Home setCurrentPage={setCurrentPage} setOrgId={setOrgId} setOrgName={setOrgName} />}
      {currentPage === 1 && (
        <Box mt={20}>
          <CreateOrganisation createNewOrg={createNewOrg} loading={loading} orgError={orgError} orgName={orgName} setOrgError={setOrgError} setOrgName={setOrgName} />
        </Box>
      )}
      {currentPage === 2 && (
        <>
          <HeaderContainer px={3} pt={1} mt={2}>
            <ArrowBackIcon style={{cursor: 'pointer'}} onClick={() => setCurrentPage(0)} color="white" />
          </HeaderContainer>

          <Box px={3} mt={10}>
            <InviteUser continueHandler={continueHandler} buttonText="Invite and add admin" onSubmit={onAddAdmin} formDataList={formDataList} setFormDataList={setFormDataList} setData={setAdminData} data={adminData} loading={loading} setError={setError} error={error} heading={`Invite admin(s) for ${orgName} organization?`} buttonTextOne="Add Another Invitation" buttonTextTwo="Send Invitation(s)" />
          </Box>
        </>
      )}
      <BottomBar
        buttonInputs={[
          {
            text: 'Home',
            onClick: () => {
              setCurrentPage(0);
            }
          },
          {
            text: 'Add Organization',
            onClick: () => {
              setCurrentPage(1);
            },
            isShow: currentPage === 1 || currentPage === 2 ? false : true
          },
          {text: 'Settings', onClick: () => {}}
        ]}
      />
    </Box>
  );
};

export default SuperAdmin;
