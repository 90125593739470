import React, {useEffect, useState} from 'react';
import {Alert, Grid, Typography} from '@mui/material';
import {Box} from '@mui/system';
import UserContainer from '../components/common/UserContainer';
import Divider from '@mui/material/Divider';
import {useNavigate} from 'react-router-dom';
import FormWrapper from '../components/common/StyledFormWrapper';
import TextInput from '../components/common/TextInput';
import MultiLineTextInput from '../components/common/MultiLineInput';
import {getGroupApi, sendEmailApi} from '../api';
import useAuthStore from '../store/authStore';
import {CONSTANTS, FILE_PATH_URL} from '../constants';
import Loader from '../components/common/Loader';

const EmailLeader = () => {
  const {user} = useAuthStore(state => state);
  const [leader, setLeader] = useState({
    avatar: '',
    first_name: '',
    last_name: '',
    mobile_number: ''
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [emailRes, setEmailRes] = useState({
    subject: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const handleChangeText = e => {
    setError(null);
    setEmailRes(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!emailRes.subject.trim() || !emailRes.message.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
      return;
    }
    const data = {
      subject: emailRes.subject.trim(),
      message: emailRes.message.trim()
    };
    try {
      setLoading(true);
      const response = await sendEmailApi(data, 'leader');
      if (response?.data?.status === 200) {
        setEmailRes({
          subject: '',
          message: ''
        });
        navigate(-1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getGroupLeaderHandler = async () => {
    try {
      setLoadingPage(true);
      const res = await getGroupApi('', user.id);
      if (res.data.status === 200) {
        setLeader(res?.data?.data?.leader_details);
      }
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getGroupLeaderHandler();
  }, []);

  return (
    <UserContainer addText="Add Disicples" isShow={false} isShow2={false} headingText={`${user?.first_name && user?.first_name} ${user?.last_name && user?.last_name}`} filePath={leader?.avatar ? `${FILE_PATH_URL}/${leader?.avatar}` : ''}>
      <Box>
        {loadingPage ? (
          <Loader />
        ) : (
          <>
            <Typography variant="h3" mt={7} fontWeight={'600'}>
              Email {leader?.first_name || ''} {leader?.last_name !== 'undefined' ? leader?.last_name : ''}
            </Typography>
            <Typography variant="h6" fontSize={14}>
              {leader?.email || ''}
            </Typography>
            {leader?.mobile_number && <Typography>{leader?.mobile_number}</Typography>}
            <Divider
              sx={{
                backgroundColor: 'white',
                width: '60%',
                marginLeft: '20%',
                marginTop: '1%'
              }}
            />

            <Box mx={2} mt={3}>
              <FormWrapper buttonInputs={[{text: 'Send Email', onClick: handleSubmit}]} headingText="" loading={loading}>
                <Box mt={2}>
                  <TextInput name="subject" placeholder="Enter Subject" value={emailRes.subject} onChange={handleChangeText} label="Subject" labelVariant="body2" />

                  <MultiLineTextInput label="Body" name="message" placeholder="Write your message" value={emailRes.message} onChange={handleChangeText} autoComplete="off" margin="normal" multiline rows={6} width="100%" isAllowSpecialCharacter={true} />
                </Box>

                <Grid item sx={{height: '50px'}}>
                  {error && <Alert severity="error">{error}</Alert>}
                </Grid>
              </FormWrapper>
            </Box>
          </>
        )}
      </Box>
    </UserContainer>
  );
};

export default EmailLeader;
