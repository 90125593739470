import React, {useEffect, useState} from 'react';
import {CssBaseline, Typography, Grid} from '@mui/material';
import FormWrapper from '../../common/StyledFormWrapper';
import {Box} from '@mui/system';
import CheckBoxInput from '../../common/CheckBoxInput';
import {getAssessmentsQuestions, postAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import Alert from '@mui/material/Alert';
import {toast} from 'react-hot-toast';

const PageTen = ({nextpage, user, setPage}) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_10, user?.id, user?.group);
      if (response?.data?.status === 200) {
        let rearrangedData = [...response?.data?.data];
        let rearrangedItem = rearrangedData?.splice(1, 1)[0];
        rearrangedData.push(rearrangedItem);
        let allQues = [];
        rearrangedData?.forEach(ques => {
          allQues.push({
            ...ques,
            answer: ques?.answer || ''
          });
        });
        setQuestions(allQues);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const submitAnswers = async type => {
    const postData = questions?.map(ques => {
      return {
        question_id: ques?.id,
        answer: ques?.answer
      };
    });
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: postData
    };
    try {
      setLoading(true);
      const response = await postAssessmentsQuestions(data);
      if (response?.data?.status === 200) {
        if (type === 'staying') {
          toast(CONSTANTS.MESSAGES.STAYING_IN, {id: 'toast_message', icon: '👏'});
          setPage(0);
        } else {
          nextpage();
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCheckBox = (val, index) => {
    setError(null);
    const updatedItems = [...questions];
    updatedItems[index].answer = val;
    setQuestions(updatedItems);
  };

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    const isAllQuestionsAnswered = questions.every(ques => ques?.answer?.trim() !== '');
    if (isAllQuestionsAnswered) {
      submitAnswers(type);
    } else {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <Box mt={1} px={3} overflow="auto">
      <CssBaseline />
      <FormWrapper
        buttonInputs={[
          {
            text: "they're staying in",
            onClick: e => {
              handleSubmit(e, 'staying');
            },
            type: 'button'
          },
          {
            text: 'they dropped',
            onClick: e => {
              handleSubmit(e, 'dropped');
            },
            type: 'submit'
          }
        ]}
        headingText="We Want To Help You Keep Them Going"
        loading={loading}
      >
        {questions &&
          questions?.length > 0 &&
          questions?.map((ques, i) => (
            <Box key={i} mt={3} style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h6" align="left">
                {ques?.question}
              </Typography>
              <CheckBoxInput onChange={() => handleCheckBox('Yes', i)} checked={ques?.answer === 'Yes'} label="Yes" />
              <CheckBoxInput onChange={() => handleCheckBox('No', i)} checked={ques?.answer === 'No'} label="No" />
            </Box>
          ))}
        <Grid item xs={12} sx={{height: '50px'}} mt={2}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default PageTen;
