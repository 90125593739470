import React from 'react';
import sdlogo from '../../../images/sdlogo.png';
import {Box, Button, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import { CONSTANTS } from '../../../constants';
import { margin } from '@mui/system';

const GetStartedFive = () => {
  const navigate = useNavigate();
  const text1 = 'We promise that you can get these same crazy results and do that one thing Jesus called us to do!';
  const text2 = 'The following resources will get you up to speed on the SD Method as you start.';

  return (
    <Box mx={3} my={5} style={{width:"75%",margin:"auto","text-align":"center"}}>
      <img src={sdlogo} width="80" height="80" />
      <Typography mt={2} variant="h3" fontWeight="medium">
        Let's Make Disciples!
      </Typography>
      <Typography mb={4} >HOW IT WORKS &bull; 5 OF 5</Typography>
      <Typography variant="h6" mb={4}>
  We promise that you can get these same crazy results and do that <b>one thing</b> Jesus called us to do!'
        
      </Typography>
      <Typography variant="h6" mb={4}>
        {text2}
      </Typography>
      <a style={{color: "white"}} href={CONSTANTS.GET_STARTED_LINKS.ONE} target='_blank'><Typography variant="h5">How to Make Disciples</Typography></a>
      <Typography pb={1}>The how-to book of everything God has shown us.</Typography>

      <a style={{color: "white"}} href={CONSTANTS.GET_STARTED_LINKS.TWO} target='_blank'><Typography variant="h5">How to Join a Live or Virtual Workshop</Typography></a>
      <Typography pb={1}>The fastest way to get going.</Typography>

      <a style={{color: "white"}} href={CONSTANTS.GET_STARTED_LINKS.THREE} target='_blank'><Typography variant="h5">Get Coaching</Typography></a>
      <Typography style={{marginBottom: "20px"}}>Stay confident as we walk alongside you all the way.</Typography>

      <Button type="submit"  onClick={() => navigate('/signup')} variant="contained" color="secondary" style={{marginTop: '20px', fontWeight: 400, marginBottom: '40px', width: '80%'}}>
        CREATE YOUR GROUP
      </Button>
      <Typography fontStyle="italic" letterSpacing={1}>
        We aren't trying to sell anything. If money is tight, we've got you covered!
      </Typography>
    </Box>
  );
};

export default GetStartedFive;