import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import theme from '../../styles/theme';
import {Typography} from '@mui/material';

const SelectInput = ({value, setValue, label, formWidth = '100%', labelVariant = 'body2', placeholder, optionArray = [], disabled}) => {
  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <FormControl sx={{width: formWidth, paddingBlock: theme.spacing(1)}}>
      {label && (
        <Typography textAlign="left" marginBottom={10} variant={labelVariant}>
          {label.toUpperCase()}
        </Typography>
      )}
      <Select variant="outlined" value={value} onChange={handleChange} disabled={disabled}>
        <MenuItem disabled value={0} style={{color: 'GrayText', background: 'white', opacity: 1, display: 'none'}}>
          {placeholder}
        </MenuItem>
        {optionArray.map((element, index) => {
          return (
            <MenuItem style={{color: 'red', background: 'white'}} key={index} value={element.value}>
              {element.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
