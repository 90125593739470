import React, {useEffect, useState} from 'react';
import UserContainer from '../components/common/UserContainer';
import PageOne from '../components/modules/Assesment/PageOne';
import PageTwo from '../components/modules/Assesment/PageTwo'; // Assuming PageTwo exists
import PageThree from '../components/modules/Assesment/PageThree';
import PageFour from '../components/modules/Assesment/PageFour';
import PageFive from '../components/modules/Assesment/PageFive';
import PageSix from '../components/modules/Assesment/PageSix';
import PageSeven from '../components/modules/Assesment/PageSeven';
import PageEight from '../components/modules/Assesment/PageEight';
import HeaderContainer from '../components/common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useParams} from 'react-router-dom';
import PageTen from '../components/modules/Assesment/PageTen';
import PageEleven from '../components/modules/Assesment/PageEleven';
import PageTwelve from '../components/modules/Assesment/PageTwelve';
import PageThirteen from '../components/modules/Assesment/PageThirteen';
import PageFourteen from '../components/modules/Assesment/PageFourteen';
import PageNine from '../components/modules/Assesment/PageNine';
import {getUsersDetails} from '../api';
import {FILE_PATH_URL} from '../constants';
import {useNavigate} from 'react-router-dom';
import Loader from '../components/common/Loader';

const Assessment = () => {
  const [page, setPage] = React.useState(0);
  const params = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const totalPage = params?.level === 'D3' ? 7 : 6;

  const nextPage = () => {
    if (params.level !== 'D3' && page === 6) {
      setPage(0);
    } else if (params.level === 'D3' && page === 7) {
      setPage(0);
    } else {
      setPage(prev => prev + 1);
    }
  };

  const previousPage = () => {
    if (page === 0) {
      navigate(-1);
    }
    if (page > 8) {
      if (page === 10) {
        setPage(page - 1);
      } else {
        setPage(0);
      }
    } else {
      setPage(page - 1);
    }
  };

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const res = await getUsersDetails(params?.userId);
      if (res?.data?.status === 200) {
        setUserData({...res?.data?.data, group: params?.groupId});
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <UserContainer onClick={() => navigate('/create-group')} filePath={userData?.avatar ? `${FILE_PATH_URL}/${userData?.avatar}` : ''} addText="Add Group" headingText={userData?.first_name && `${userData?.first_name} ${userData?.last_name}`} subHeadingText="" isShow2={false} isShow={false}>
      <HeaderContainer px={3} pt={1}>
        <ArrowBackIcon style={{cursor: 'pointer'}} onClick={previousPage} color="white" sx={{color: 'white'}} />
      </HeaderContainer>
      {loading ? (
        <Loader />
      ) : (
        <>
          {page === 0 && <PageOne nextpage={nextPage} setPage={setPage} user={userData} />}
          {/* assessment screens start */}
          {page === 1 && <PageTwo nextpage={nextPage} user={userData} page={page} totalPage={totalPage} />}
          {page === 2 && <PageThree nextpage={nextPage} user={userData} page={page} totalPage={totalPage} />}
          {page === 3 && <PageFour nextpage={nextPage} user={userData} page={page} totalPage={totalPage} />}
          {page === 4 && <PageFive nextpage={nextPage} user={userData} page={page} totalPage={totalPage} />}
          {/* show only D3 */}
          {page === 7 && <PageSix nextpage={nextPage} user={userData} page={page} totalPage={totalPage} />}
          {/* {page === 6 && <PageSeven nextpage={nextPage} user={userData} page={page} totalPage={totalPage} />} */}
          {page === 6 && <PageEight nextpage={nextPage} user={userData} page={page} totalPage={totalPage} />}
          {page === 5 && <PageNine nextpage={nextPage} user={userData} page={page} totalPage={totalPage} />}

          {/* assessment dropping screens */}
          {page === 9 && <PageTen nextpage={nextPage} user={userData} setPage={setPage} />}
          {page === 10 && <PageEleven nextpage={() => navigate(-1)} user={userData} />}

          {/* Level Screens */}
          {page === 11 && <PageTwelve nextpage={() => setPage(0)} user={userData} />}
          {page === 12 && <PageThirteen nextpage={() => setPage(0)} user={userData} />}
          {page === 13 && <PageFourteen nextpage={() => setPage(0)} user={userData} />}
        </>
      )}
    </UserContainer>
  );
};

export default Assessment;
