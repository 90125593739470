import {Alert, Box, Grid} from '@mui/material';
import React, {useState} from 'react';
import FormWrapper from '../components/common/StyledFormWrapper';
import TextInput from '../components/common/TextInput';
import MultiLineTextInput from '../components/common/MultiLineInput';
import HeaderContainer from '../components/common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {sendEmailApi} from '../api';
import {CONSTANTS} from '../constants';

const ContactUs = () => {
  const [emailRes, setEmailRes] = useState({
    subject: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChangeText = e => {
    setError(null);
    setEmailRes(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
  };
  const handleSubmit = async () => {
    if (!emailRes.message.trim() || !emailRes.subject.trim()) {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
      return;
    }
    try {
      setLoading(true);
      const res = await sendEmailApi(emailRes, 'help');
      if (res?.data?.status === 200) {
        navigate(-1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <HeaderContainer mx={2}>
        <ArrowBackIcon
          style={{cursor: 'pointer', color: 'white'}}
          onClick={() => {
            navigate(-1);
            setEmailRes({
              subject: '',
              message: ''
            });
          }}
          color="white"
        />
        <Typography ml={1} variant="h4">
          Contact Us
        </Typography>
      </HeaderContainer>
      <Box mx={2} mt={8}>
        <FormWrapper buttonInputs={[{text: 'Send Email', onClick: handleSubmit}]} headingText="" loading={loading}>
          <Box mt={2}>
            <TextInput name="subject" placeholder="Enter Subject" value={emailRes.subject} onChange={handleChangeText} label="Subject" labelVariant="body2" />

            <MultiLineTextInput label="Body" name="message" placeholder="Write your message" value={emailRes.message} onChange={handleChangeText} autoComplete="off" margin="normal" multiline rows={6} width="100%" />
          </Box>

          <Grid item xs={12} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default ContactUs;
