import {Alert, Avatar, Button, Grid, Typography} from '@mui/material';
import {Box} from '@mui/system';
import React, {useState} from 'react';
import CheckBoxInput from '../../common/CheckBoxInput';
import {CONSTANTS} from '../../../constants';
import HeaderContainer from '../../common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from 'react-router-dom';
import Loader from '../../common/Loader';

const DiscipleItem = ({disciple, setEmails, emails, setError}) => {
  const handleChecked = email => {
    setError(null);
    setEmails(prevEmails => {
      if (prevEmails.includes(email)) {
        return prevEmails.filter(em => em !== email);
      } else {
        return [...prevEmails, email];
      }
    });
  };

  return (
    <Box py={2} display="flex" alignItems="center" sx={{cursor: 'pointer'}} justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <CheckBoxInput checked={emails.includes(disciple?.email)} onChange={() => handleChecked(disciple?.email)} />
        <Avatar sx={{width: 56, height: 56}} alt={disciple?.first_name} src={disciple?.avatarSrc} />
        <Box textAlign="left" ml={2}>
          <Typography variant="h5" fontWeight={600}>
            {disciple?.first_name + ' ' + disciple?.last_name}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" fontSize={14} sx={{marginRight: '4px'}}>
              Email:
            </Typography>
            <Typography variant="h6" fontSize={14}>
              {disciple?.email}
            </Typography>
          </Box>
          {disciple?.title && (
            <Typography variant="p" color="white" fontSize={14}>
              {disciple?.title?.replace(/,/g, ' • ')}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const GroupSelectUsers = ({loading, disciples, setEmails, emails, setIsDiscipleSelected}) => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  return (
    <>
      <HeaderContainer>
        <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => navigate(-1)} color="white" />
      </HeaderContainer>
      {loading ? (
        <Loader />
      ) : disciples && disciples?.length > 0 ? (
        <Box>{disciples?.length && disciples.map(dis => <DiscipleItem key={dis?.id} disciple={dis} setEmails={setEmails} emails={emails} setError={setError} />)}</Box>
      ) : (
        <Box marginTop={10}>
          <Typography variant="h5" marginBottom={4}>
            Invite your first people!
          </Typography>
          <Typography variant="p" color="white">
            Once a disciple is added manually or accepts an invitation, they will be added here
          </Typography>
        </Box>
      )}
      <Grid item sx={{height: '50px'}}>
        {error && <Alert severity="error">{error}</Alert>}
      </Grid>
      <Box my={2}>
        <Button
          sx={{fontWeight: 400}}
          onClick={() => {
            emails.length > 0 ? setIsDiscipleSelected(true) : setError(CONSTANTS.MESSAGES.SELECT_ATLEAST_DISC);
          }}
          variant="contained"
          color="secondary"
        >
          Proceed
        </Button>
      </Box>
    </>
  );
};

export default GroupSelectUsers;
