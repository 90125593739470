import React, {useEffect} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import TextInput from '../../common/TextInput';
import {capitalizeWords, isSpecialCharacterIncludes} from '../../../utils';
import {Alert, Box, Grid} from '@mui/material';

const CreateOrganisation = ({createNewOrg, loading, orgName, setOrgName, setOrgError, orgError}) => {
  useEffect(() => {
    return () => {
      setOrgName('');
    };
  }, []);
  return (
    <Box mt={1} px={3} overflow="auto">
      <FormWrapper
        buttonInputs={[
          {
            text: 'Create',
            onClick: e => {
              createNewOrg(e);
            },
            type: 'submit'
          }
        ]}
        headingText="Add New Organization"
        loading={loading}
      >
        <TextInput
          value={orgName}
          placeholder="Enter Organization Name"
          onChange={e => {
            setOrgError(null);
            setOrgName(capitalizeWords(e.target.value));
          }}
          label="Organization Name"
          error={orgName && isSpecialCharacterIncludes(orgName)}
          helperText={orgName && isSpecialCharacterIncludes(orgName) ? 'Enter valid organization name' : ''}
          labelVariant="body2"
        />
        <Grid item xs={12} sx={{height: '50px'}}>
          {orgError && <Alert severity="error">{orgError}</Alert>}
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default CreateOrganisation;
