import {CssBaseline, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import {Box} from '@mui/system';
import CheckBoxInput from '../../common/CheckBoxInput';
import MultiLineTextInput from '../../common/MultiLineInput';
import {getAssessmentsQuestions, postAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import {useParams} from 'react-router-dom';
import Alert from '@mui/material/Alert';

const PageEleven = ({nextpage, user}) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const params = useParams();

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_11, user?.id, user?.group);
      if (response?.data?.status === 200) {
        let allQues = [];
        response?.data?.data?.forEach(ques => {
          allQues.push({
            ...ques,
            answer: ques.id === 38 ? (ques?.answer ? ques?.answer?.split(',') : []) : ques?.answer || ''
          });
        });
        setQuestions(allQues);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const submitAnswers = async () => {
    const postData = questions?.map(ques => {
      if (ques.id === 38) {
        return {
          question_id: ques?.id,
          answer: ques?.answer?.join(',')
        };
      } else {
        return {
          question_id: ques?.id,
          answer: ques?.answer
        };
      }
    });
    const data = {
      user_id: Number(user?.id),
      group_id: user?.group,
      questions: postData
    };
    try {
      setLoading(true);
      const response = await postAssessmentsQuestions(data);
      if (response?.data?.status === 200) {
        nextpage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handelChangeInput = (val, index, id) => {
    setError(null);
    const updatedItems = [...questions];
    if (id === 38) {
      if (updatedItems[index]?.answer?.includes(val)) {
        updatedItems[index].answer = updatedItems[index]?.answer?.filter(value => value !== val);
      } else {
        updatedItems[index]?.answer?.push(val);
      }
    } else {
      updatedItems[index].answer = val;
    }
    setQuestions(updatedItems);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isAllQuestionsAnswered = questions.every(ques => (ques?.id === 38 ? ques?.answer?.length > 0 : ques?.answer?.trim() !== ''));
    if (isAllQuestionsAnswered) {
      submitAnswers();
    } else {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <>
      <CssBaseline />
      <Box mt={1} px={3} overflow="auto">
        <FormWrapper style={{width: '100%', marginTop: '20px'}} buttonInputs={[{text: 'Submit', onClick: e => handleSubmit(e), type: 'submit'}]} headingText="It's not over yet" loading={loading}>
          <Typography variant="body2" textAlign="left" sx={{marginBottom: '20px'}}>
            Don’t get discouraged. Most people who drop in {params?.level} come back later.
          </Typography>

          <Typography variant="h6" align="left" mt={1}>
            WHY DID THEY DROP?
          </Typography>

          {questions?.map((ques, index) => {
            if (ques?.id == 38) {
              return (
                <Grid sx={{display: 'flex', flexWrap: 'wrap'}} key={index}>
                  <CheckBoxInput width="50%" onChange={() => handelChangeInput('Money', index, ques?.id)} checked={ques?.answer?.includes('Money')} label="Money" />
                  <CheckBoxInput width="50%" onChange={() => handelChangeInput('Pride', index, ques?.id)} checked={ques?.answer?.includes('Pride')} label="Pride" />
                  <CheckBoxInput width="50%" onChange={() => handelChangeInput('Family', index, ques?.id)} checked={ques?.answer?.includes('Family')} label="Family" />
                  <CheckBoxInput width="50%" onChange={() => handelChangeInput('Schedule', index, ques?.id)} checked={ques?.answer?.includes('Schedule')} label="Schedule" />
                </Grid>
              );
            }
            if (ques?.id == 39) {
              return (
                <Grid key={index} item xs={12} mt={2}>
                  <MultiLineTextInput placeholder="Comments" value={ques?.answer} onChange={e => handelChangeInput(e.target.value, index, ques?.id)} autoComplete="off" margin="normal" multiline rows={4} />
                </Grid>
              );
            }
          })}
          <Grid item xs={12} sx={{height: '55px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default PageEleven;
