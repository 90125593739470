import React, {useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Alert, Grid, Box} from '@mui/material';
import TextInput from '../components/common/TextInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormWrapper from '../components/common/StyledFormWrapper';
import {useNavigate} from 'react-router-dom';
import useDebounce from '../hooks/useDebounce';
import {CONSTANTS} from '../constants';
import {resetPasswordSendOTPApi, verifyAndResetPasswordApi} from '../api/index';
import PasswordChecklist from 'react-password-checklist';
import {validateEmail} from '../utils';
import {useCountDown} from '../hooks/useCountDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HeaderContainer from '../components/common/HeaderBox';

const ForgotPassword = () => {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(null);
  const debounceEmail = useDebounce(email, CONSTANTS.DEBOUNCE_TIMEOUT);
  const [isRunning, startCountdown, formattedTime] = useCountDown(CONSTANTS.COUNTDOWN);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const sendOTPHandler = async isResend => {
    try {
      setLoading(true);
      const response = await resetPasswordSendOTPApi(email);
      if (response?.data?.status === 200) {
        startCountdown();
        if (isResend) {
          setIsOtpSent(isResend);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const verifyOTPAndResetPasswordHandler = async () => {
    if (!isValidPassword) {
      return;
    }
    const data = {email, password, otp: otp};
    try {
      setLoading(true);
      const response = await verifyAndResetPasswordApi(data);
      if (response?.data?.status === 200) {
        navigate('/login');
        setIsOtpSent(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClick = e => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError(CONSTANTS.MESSAGES.EMAIL_REQUIRED);
      return;
    }
    if (isOtpSent) {
      if (!email || !password || !confirmPassword) {
        setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
        return;
      }
      if (otp?.length !== CONSTANTS.OTP_LENGTH) {
        setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
        return;
      }
      verifyOTPAndResetPasswordHandler();
    } else {
      sendOTPHandler(true);
    }
  };

  const backHandler = () => {
    setIsOtpSent(false);
    setPassword('');
    setConfirmPassword('');
    setOtp(new Array(CONSTANTS.OTP_LENGTH).fill(''));
  };
  return (
    <>
      <CssBaseline />
      <Box mt={3} px={3} overflow="auto">
        <HeaderContainer position={isOtpSent ? 'normal' : 'fixed'} top={50} mb={2}>
          <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => (isOtpSent ? backHandler() : navigate(-1))} />
        </HeaderContainer>
        <FormWrapper
          buttonInputs={[
            {
              text: isOtpSent ? 'Verify and Reset Password' : 'Send OTP',
              onClick: handleClick,
              type: 'submit'
            }
          ]}
          headingText="Forgot Password"
          loading={loading}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} marginBottom={1}>
              {!isOtpSent && (
                <TextInput
                  disabled={isOtpSent}
                  value={email}
                  placeholder="Email"
                  autoComplete="off"
                  label="Email"
                  isAllowSpecialChar={true}
                  onChange={e => {
                    setError(null);
                    setEmail(String(e.target.value).replace(/\s+/g, ''));
                  }}
                  error={debounceEmail && !validateEmail(email)}
                  helperText={debounceEmail && !validateEmail(email) ? CONSTANTS.MESSAGES.INVALID_EMAIL : ''}
                  labelVariant="body2"
                />
              )}
            </Grid>
            {isOtpSent && (
              <>
                <Grid item xs={12} marginBottom={3}>
                  <TextInput
                    value={otp}
                    placeholder="OTP"
                    autoComplete="off"
                    label="OTP"
                    isAllowSpecialChar={true}
                    onChange={e => {
                      setError(null);
                      setOtp(String(e.target.value).replace(!CONSTANTS.REGEX.ONLY_APHANUMERIC, ''));
                    }}
                    labelVariant="body2"
                    maxLength={4}
                  />
                  <div className="otp-resend">
                    <span onClick={() => (isRunning ? () => {} : sendOTPHandler(false))} className="underline-text" style={{color: isRunning ? 'lightgray' : 'white'}}>
                      Resend OTP? {isRunning && <span className="timer">({formattedTime})</span>}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} marginBottom={1}>
                  <div className="relative_container">
                    <TextInput
                      autoComplete="off"
                      label="Password"
                      value={password}
                      isAllowSpecialChar={true}
                      placeholder="Password"
                      onChange={e => {
                        setPassword(e.target.value);
                        setError(null);
                      }}
                      type={showPassword ? 'text' : 'password'}
                      labelVariant="body2"
                    />
                    {showPassword ? <VisibilityIcon onClick={() => setShowPassword(false)} className="eye_icon" /> : <VisibilityOffIcon onClick={() => setShowPassword(true)} className="eye_icon" />}
                  </div>
                </Grid>
                <Grid item xs={12} marginBottom={1}>
                  <div className="relative_container">
                    <TextInput
                      autoComplete="off"
                      label="Confirm Password"
                      value={confirmPassword}
                      isAllowSpecialChar={true}
                      placeholder="Confirm Password"
                      onChange={e => {
                        setConfirmPassword(e.target.value);
                        setError(null);
                      }}
                      type={showConfirmPassword ? 'text' : 'password'}
                      labelVariant="body2"
                    />
                    {showConfirmPassword ? <VisibilityIcon onClick={() => setShowConfirmPassword(false)} className="eye_icon" /> : <VisibilityOffIcon onClick={() => setShowConfirmPassword(true)} className="eye_icon" />}
                  </div>
                  <PasswordChecklist
                    rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
                    minLength={8}
                    validColor="#FFFFFF"
                    value={password}
                    valueAgain={confirmPassword}
                    style={{textAlign: 'start'}}
                    messages={{
                      minLength: CONSTANTS.MESSAGES.PASSWORD_INFO.LENGTH,
                      specialChar: CONSTANTS.MESSAGES.PASSWORD_INFO.SPECIAL_CHARACTERS,
                      number: CONSTANTS.MESSAGES.PASSWORD_INFO.NUMBER,
                      capital: CONSTANTS.MESSAGES.PASSWORD_INFO.UPPERCASE,
                      lowercase: CONSTANTS.MESSAGES.PASSWORD_INFO.LOWERCASE,
                      match: CONSTANTS.MESSAGES.PASSWORD_INFO.MATCH
                    }}
                    onChange={isValid => setIsValidPassword(isValid)}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sx={{height: '55px'}}>
              {error && <Alert severity="error">{error}</Alert>}
            </Grid>
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default ForgotPassword;
