import React, {useEffect, useState} from 'react';
import {Box, List, ListItem, ListItemText, ListItemIcon, Typography, useMediaQuery} from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import AdminStructure from './common/AdminStructure';
import useAdminStore from '../../store/adminStore';
import {getDashboardData} from '../../api';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import {Bar} from 'react-chartjs-2';
import {Chart, registerables} from 'chart.js';
Chart.register(...registerables);

function Dashboard() {
  const {organizationSelected} = useAdminStore(state => state);
  const [dashboardData, setDashboardData] = useState();
  const [loadingPage, setLoadingPage] = useState(false);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const getDashboardDataHandler = async () => {
    try {
      setLoadingPage(true);
      const response = await getDashboardData();
      if (response?.data?.status === 200) {
        setDashboardData(response?.data?.data);
      }
      setLoadingPage(false);
    } catch (err) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getDashboardDataHandler();
  }, [organizationSelected]);

  const years = dashboardData?.progressionData?.Years || [];
  const groupsData = [];
  const disciplesData = [];
  const successData = [];

  years.forEach(year => {
    groupsData.push(dashboardData?.progressionData['Groups'][year] || 0);
    disciplesData.push(dashboardData?.progressionData['Disciples'][year] || 0);
    successData.push(dashboardData?.progressionData['Success'][year] || 0);
  });

  const chartData = {
    labels: years,
    datasets: [
      {
        label: 'Groups',
        backgroundColor: '#171716',
        borderColor: '#171716',
        borderWidth: 1,
        hoverBackgroundColor: '#171716',
        hoverBorderColor: '#171716',
        data: groupsData
      },
      {
        label: 'Disciples',
        backgroundColor: '#9e0001',
        borderColor: '#9e0001',
        borderWidth: 1,
        hoverBackgroundColor: '#9e0001',
        hoverBorderColor: '#9e0001',
        data: disciplesData
      },
      {
        label: 'Success',
        backgroundColor: '#cecece',
        borderColor: '#cecece',
        borderWidth: 1,
        hoverBackgroundColor: '#cecece',
        hoverBorderColor: '#cecece',
        data: successData
      }
    ]
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            stepSize: 10
          }
        }
      ]
    }
  };

  return (
    <div
      style={{
        backgroundImage: 'none !important',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        width: '100vw',
        overflowY: isSmallScreen ? 'auto' : 'hidden'
      }}
    >
      <AdminStructure isSearch={true} heading="Dash">
        {loadingPage ? (
          <Loader color="#9e0001" />
        ) : !dashboardData ? (
          <NoDataFoundMessage mt={5} message="No data available for this organization" />
        ) : (
          <Box
            display="grid"
            gridTemplateColumns={isSmallScreen ? '1fr' : 'repeat(2, 1fr)'} // Adjust grid for small screens
            gap={2}
            padding={2}
          >
            {/* Quick Look */}
            {dashboardData && (
              <Box bgcolor="#FFF" borderRadius="12px" padding={2} minHeight="200px" height={'350px'}>
                <Typography variant="h5" gutterBottom color="#000" textAlign="left" pl={3} pt={2}>
                  Quick Look
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <GroupsIcon htmlColor="#d32f2f" style={{fontSize: 34}} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography color="black" fontWeight={'bold'} variant={isSmallScreen ? 'h5' : 'h4'}>
                        {dashboardData.quickLookData['Groups']['Active']} Active Groups
                      </Typography>
                      <Typography color="#d32f2f" variant="body1">
                        {dashboardData.quickLookData['Groups']['Total']} Lifetime Groups &bull; {dashboardData.quickLookData['Groups']['Finished']} Finished Groups
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <PersonIcon htmlColor="#d32f2f" style={{fontSize: 34}} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography color="black" fontWeight={'bold'} variant={isSmallScreen ? 'h5' : 'h4'}>
                        {dashboardData.quickLookData['Disciples']['Active']} Disciples in Process
                      </Typography>
                      <Typography color="#d32f2f" variant="body1">
                        {dashboardData.quickLookData['Disciples']['Total']} Lifetime Disciples &bull; {dashboardData.quickLookData['Disciples']['Finished']} Disciples Finished
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <PersonIcon htmlColor="#d32f2f" style={{fontSize: 34}} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography color="black" fontWeight={'bold'} variant={isSmallScreen ? 'h5' : 'h4'}>
                        {dashboardData.quickLookData['Leaders']['Active']} Active Leaders
                      </Typography>
                      <Typography color="#d32f2f" variant="body1">
                        {dashboardData.quickLookData['Leaders']['Total']} Lifetime Leaders &bull; {dashboardData.quickLookData['Leaders']['Inactive']} Inactive Leaders
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
            )}
            {/* Process Stats */}
            <Box bgcolor="#FFF" borderRadius="12px" padding={2} minHeight="200px" height={'350px'}>
              <Typography gutterBottom variant="h5" color="#000" textAlign="left" pl={3} pt={3}>
                Through the Process Stats
              </Typography>
            </Box>
            {/* Progression */}
            {dashboardData && (
              <Box bgcolor="#FFF" borderRadius="12px" padding={2} minHeight="200px" height={'350px'}>
                <Typography gutterBottom variant="h5" color="#000" textAlign="left" pl={3} pt={3}>
                  Progression by Year
                </Typography>
                <Box height="300px" width={isSmallScreen ? 'auto' : '100%'}>
                  <Bar data={chartData} options={chartOptions} />
                </Box>
              </Box>
            )}
            {/* Phase Stats */}
            {dashboardData && (
              <Box bgcolor="#FFF" borderRadius="12px" padding={2} minHeight="200px" height={'350px'}>
                <Typography gutterBottom variant="h5" color="#000" textAlign="left" pl={2} pt={3}>
                  Phase Stats
                </Typography>
                <List>
                  <ListItem dense>
                    <ListItemText
                      sx={{
                        '& .MuiListItemText-primary': {
                          color: '#000'
                        },
                        marginBottom: -2, // Adjust as needed
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                      primary="In D1"
                      secondary={dashboardData.phaseData['Total'].D1_finished + dashboardData.phaseData['Total'].D1_started}
                    />
                  </ListItem>
                  <ListItem dense>
                    <ListItemText
                      sx={{
                        '& .MuiListItemText-primary': {
                          color: '#000'
                        },
                        marginBottom: 6,
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                      primary="Finished D1"
                      secondary={dashboardData.phaseData['Total'].D1_finished}
                    />
                  </ListItem>
                  <ListItem dense>
                    <ListItemText
                      sx={{
                        '& .MuiListItemText-primary': {
                          color: '#000'
                        },
                        marginBottom: -2,
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                      primary="In D2"
                      secondary={dashboardData.phaseData['Total'].D2_finished + dashboardData.phaseData['Total'].D2_started}
                    />
                  </ListItem>
                  <ListItem dense>
                    <ListItemText
                      sx={{
                        '& .MuiListItemText-primary': {
                          color: '#000'
                        },
                        // marginBottom: 10,
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                      primary="Finished D2"
                      secondary={dashboardData.phaseData['Total'].D2_finished}
                    />
                  </ListItem>
                  <ListItem dense>
                    <ListItemText
                      sx={{
                        '& .MuiListItemText-primary': {
                          color: '#000'
                        },
                        marginBottom: -2,
                        marginTop: 8,
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                      primary="In D3"
                      secondary={dashboardData.phaseData['Total'].D3_finished + dashboardData.phaseData['Total'].D3_started}
                    />
                  </ListItem>
                  <ListItem dense>
                    <ListItemText
                      sx={{
                        '& .MuiListItemText-primary': {
                          color: '#000'
                        },
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                      primary="Finished D3"
                      secondary={dashboardData.phaseData['Total'].D3_finished}
                    />
                  </ListItem>
                </List>
              </Box>
            )}
          </Box>
        )}
      </AdminStructure>
    </div>
  );
}

export default Dashboard;
