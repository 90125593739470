import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {Typography} from '@mui/material';

const RadioButtonGroup = ({label, options, selectedOption, onChange, ...rest}) => {
  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <Typography textAlign="left" component="legend">
        {label}
      </Typography>
      <RadioGroup aria-label={label} name={label} value={selectedOption} onChange={handleChange} {...rest}>
        {options.map(option => (
          <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroup;
