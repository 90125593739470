import {useState, useEffect} from 'react';
import {Box, Typography, FormControl, MenuItem, Select} from '@mui/material';
import FormWrapper from '../../common/StyledFormWrapper';
import {getOrganizationsApi} from '../../../api';
import useAuthStore from '../../../store/authStore';
import CheckBoxInput from '../../common/CheckBoxInput';
import Loader from '../../common/Loader';
import { Link } from 'react-router-dom';

const PageTwo = ({nextPage, setGroupData}) => {
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [organization, setOrganization] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [loading, setLoading] = useState(false);
  const {user: userData} = useAuthStore(state => state);
  const [isPublicAndHasOrganzations, setIsPublicAndHasOrganzations] = useState(userData?.is_public == 1 ? true : false);
  const [pageLoading, setPageLoading] = useState(false);

  const getUsersOrganizations = async () => {
    try {
      setPageLoading(true);
      const response = await getOrganizationsApi(userData?.id);
      if (response?.data?.status === 200) {
        if (response?.data?.data?.length > 0) {
          setIsPublicAndHasOrganzations(false);
        }
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  };

  const fetchOrgs = async () => {
    try {
      setLoading(true);
      const response = await getOrganizationsApi(isPublicAndHasOrganzations ? '' : userData?.id);
      if (response?.data?.status === 200) {
        setOrganization(response?.data?.data);
        if (response?.data?.data?.length === 1 && selectedOrganization === '') {
          setSelectedOrganization(response?.data?.data[0]?.id);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOrganizationChange = event => {
    setError(false);
    setSelectedOrganization(event.target.value);
  };

  const handleContinue = () => {
    if (isPublicAndHasOrganzations && !checked) {
      nextPage();
      return;
    }
    if (!selectedOrganization) {
      setError(true);
      return;
    }
    setGroupData(prevData => ({
      ...prevData,
      organization_id: selectedOrganization
    }));
    nextPage();
  };

  useEffect(() => {
    getUsersOrganizations();
  }, []);

  useEffect(() => {
    fetchOrgs();
  }, [isPublicAndHasOrganzations]);
  return (
    <FormWrapper buttonInputs={[{text: 'Continue', onClick: handleContinue}]} headingText={isPublicAndHasOrganzations ? 'Are You With an Organization?' : 'Select your Organization'} loading={loading}>
      <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight={300} mt={1}>
        {pageLoading ? (
          <Loader color="#FFFFFF" />
        ) : (
          <>
            {isPublicAndHasOrganzations && (
              <Box display="flex" justifyContent="space-evenly">
                <CheckBoxInput onChange={() => setChecked(true)} checked={checked} label="Yes" />
                <CheckBoxInput onChange={() => setChecked(false)} checked={!checked} label="No" />
              </Box>
            )}
            {(checked || !isPublicAndHasOrganzations) && (
              <FormControl sx={{width: '100%', paddingBlock: 1}}>
                <Typography textAlign="left" marginBottom={10} variant="body2">
                  Pick Your Organization
                </Typography>
                <Select placeholder="Select Organization" value={selectedOrganization} onChange={handleOrganizationChange} displayEmpty sx={{border: 'none'}}>
                  <MenuItem sx={{display: 'none', height: '0px'}} value="" disabled selected>
                    <em>Select Organization</em>
                  </MenuItem>
                  {organization.map((org, index) => {
                    return (
                      <MenuItem style={{color: 'red', background: 'white'}} key={org.id} value={org.id}>
                        {org.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Box height="70px" display="flex" flexDirection="column">
                  <Typography textAlign="left" color="white" variant="caption">
                    If you don't see your organization in the drop down, <Link to="/contact-us" style={{color:"white", textDecoration: "underline"}}>Email us</Link> and we'll help you get set-up.
                  </Typography>
                  {error && (
                    <Typography mt={2} align="left" color="white" fontSize={12} variant="p">
                      Select the organization
                    </Typography>
                  )}
                </Box>
              </FormControl>
            )}
          </>
        )}
      </Box>
    </FormWrapper>
  );
};

export default PageTwo;
