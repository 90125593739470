import React from 'react';
import sdlogo from '../../../images/sdlogo.png';
import {Box, Button, Typography} from '@mui/material';

const GetStartedTwo = ({setPage}) => {
  const text1 = 'The focus is on transformation instead of education. The goal is to get disciples prepared, confident, and skilled to live the abundant life God promised.';
  const text2 = 'The secret is that healthy followers do healthy things. The result is that 95% of disciples made go on to make disciples without being asked.';
  return (
    <Box mx={3} my={5}>
      <img src={sdlogo} width="80" height="80" />
      
      <Typography mt={2} variant="h3" fontWeight="bold">
        The SD Method<br/> A Quick Look
      </Typography>
        {/* <Typography mt={2} variant="h3" fontWeight="medium">
        A Quick Look
      </Typography> */}
      <Typography mb={2}>HOW IT WORKS &bull; 2 OF 5</Typography>
      <Typography variant="h5" pt={2} textAlign="left" fontWeight="bold" mb={1}>
        One Focus
      </Typography>
      <Typography variant="h6" textAlign="left">
        {text1}
      </Typography>
      <Typography variant="h6" py={2} textAlign="left">
        {text2}
      </Typography>
      <Typography variant="h5" pt={2} textAlign="left" fontWeight="bold" mb={1}>
        Five Foundations
      </Typography>
      <ul style={{color: 'white', textAlign: 'left', paddingLeft: '21px', marginBottom: "20px"}}>
        <li style={{marginBottom: "4px"}}>The Bible is the Fuel.</li>
        <li style={{marginBottom: "4px"}}>Disciples are made Intentionally.</li>
        <li style={{marginBottom: "4px"}}>A Simple Definition of Disciple Making.</li>
        <li style={{marginBottom: "4px"}}>The Focus is on the Individual.</li>
        <li>The Process is done Along the Way.</li>
      </ul>

      <Button type="submit" fullWidth onClick={() => setPage(4)} variant="contained" color="secondary" style={{marginTop: '20px', fontWeight: 400, marginBottom: '40px'}}>
        WHY DOES IT WORK?
      </Button>
    </Box>
  );
};

export default GetStartedTwo;
