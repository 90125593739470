import UserContainer from '../components/common/UserContainer';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import GroupSelectUsers from '../components/modules/GroupEmail/GroupSelectUsers';
import GroupSendEmail from '../components/modules/GroupEmail/GroupSendEmail';
import {Box} from '@mui/material';
import {toast} from 'react-hot-toast';
import {BASE_URL, CONSTANTS, FILE_PATH_URL} from '../constants';
import {getGroupApi, getGroupDiscipleApi} from '../api';
import {isValidFile} from '../utils';
import moment from 'moment';
import useAuthStore from '../store/authStore';

const GroupEmail = () => {
  const [groupData, setGroupData] = useState([]);
  const [disciples, setDisciples] = useState([]);
  const [isDiscipleSelected, setIsDiscipleSelected] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailRes, setEmailRes] = useState({
    subject: '',
    message: '',
    recfile: ''
  });
  const [sendLoader, setSendLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {token} = useAuthStore(state => state);
  const params = useParams();

  const navigate = useNavigate();

  const getGroupDetailsHandler = async () => {
    try {
      const res = await getGroupDiscipleApi(params?.id);
      if (res?.data?.status === 200) {
        setDisciples(res?.data?.data);
      }
    } catch (error) {}
  };

  const getGroupHandler = async () => {
    try {
      const res = await getGroupApi(params?.id);
      if (res?.data?.status === 200) {
        setGroupData(res?.data?.data);
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    if (!emailRes.message || !emailRes?.subject) {
      setError(CONSTANTS.MESSAGES.SUBJECT_AND_BODY_REQUIRED);
      return;
    }
    if (emailRes?.recfile) {
      if (!isValidFile('doc', emailRes?.recfile)) {
        setError(CONSTANTS.MESSAGES.PDF_ALLOWED);
        return;
      }
      if (emailRes?.recfile?.size > CONSTANTS.MAX_PDF_FILE_SIZE) {
        setError(CONSTANTS.MESSAGES.FILE_LARGE);
        return;
      }
    }
    setSendLoader(true);
    const formData = new FormData();
    formData.append('subject', emailRes.subject);
    formData.append('message', emailRes.message);
    if (emailRes.recfile) {
      formData.append('recfile', emailRes.recfile);
    }
    formData.append('isSendEmail', 'true');
    formData.append('group_id', groupData?.id);
    formData.append('emails', emails);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: token
      },
      body: formData
    };
    try {
      const res = await fetch(`${BASE_URL}/send-invite`, requestOptions);
      const data = await res.json();
      if (data?.status === 200) {
        toast.success('Email Sent Successfully', {id: 'toast_message'});
        navigate(-1);
      }
      setSendLoader(false);
    } catch (error) {
      setSendLoader(false);
    }
  };

  useEffect(() => {
    const getGroupById = getGroupHandler();
    const getGroupDetails = getGroupDetailsHandler();
    setLoading(true);
    Promise.all([getGroupById, getGroupDetails])
      .then(results => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, []);

  return (
    <UserContainer onClick={() => navigate('/create-group')} addText="Add Disciple" isShow2={false} headingText={groupData?.name ? `${groupData?.name}'s Group` : ''} subHeadingText={groupData?.group_meeting?.meeting_time ? `${moment(new Date(groupData?.group_meeting?.meeting_time)).format('h:mm A')} on ${groupData?.group_meeting?.meeting_day} at ${groupData?.group_meeting?.meeting_place}` : ''} filePath={`${FILE_PATH_URL}/${groupData?.avatar}`} isShow={false}>
      <Box pt={1} px={3}>
        {isDiscipleSelected ? <GroupSendEmail setEmailRes={setEmailRes} emailRes={emailRes} handleSubmit={handleSubmit} loading={sendLoader} setError={setError} error={error} setIsDiscipleSelected={setIsDiscipleSelected} /> : <GroupSelectUsers loading={loading} disciples={disciples} setEmails={setEmails} emails={emails} setIsDiscipleSelected={setIsDiscipleSelected} />}
      </Box>
    </UserContainer>
  );
};

export default GroupEmail;
