import React, {useState, useEffect} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Typography} from '@mui/material';
import {getAssessmentsQuestions, postAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import {Grid} from '@mui/material';
import DatePicker from '../../common/DatePicker';
import QuestionHeadings from './QuestionHeadings';
import {mergeConsecutiveSteps} from '../../../utils';
import moment from 'moment';

const PageFour = ({nextpage, user, page, totalPage}) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState({
    13: false,
    14: false,
    15: false,
    16: false,
    17: false,
    18: false,
    19: false,
    44: false,
    45: false,
    46: false,
    47: false,
    48: false,
    49: false,
    50: false
  });
  const [maxDateArray, setMaxDateArray] = useState([]);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_3, user?.id, user?.group);
      if (response?.data?.status === 200) {
        const sortedData = response?.data?.data?.sort((a, b) => a.id - b.id);
        const sortedQuestion = mergeConsecutiveSteps(sortedData);
        setQuestions(sortedQuestion);
        const initialAnswers = sortedData?.map((item, index) => {
          return {
            id: item?.id,
            stepCount: item?.answer?.split('|')[1] ? item?.answer?.split('|')[1] : '',
            answer: item?.answer?.split('|')[0] ? item?.answer?.split('|')[0] : '',
            updated_by: item?.updated_by
          };
        }, {});
        setAnswers(initialAnswers);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAnswerChange = (questionId, value) => {
    setError(null);
    const id1 = Number(questionId?.split(',')[0]);
    const id2 = Number(questionId?.split(',')[1]);
    let copiedData = [...answers];
    const index = copiedData.findIndex(obj => Number(obj.id) === id1);
    const index2 = copiedData.findIndex(obj => Number(obj.id) === id2);

    if (index !== -1) {
      copiedData[index]['answer'] = moment(value).format();
    }
    if (index2 !== -1 && copiedData[index2].stepCount) {
      copiedData[index2]['answer'] = moment(value).format();
    }
    setAnswers([...copiedData]);
  };

  function checkFirstItem(array) {
    if (array.length === 0) {
      return false;
    }

    const firstItem = array[0];

    const hasAnswer = firstItem.hasOwnProperty('answer') && firstItem.answer !== '';
    const hasStepCount = firstItem.hasOwnProperty('stepCount') && firstItem.stepCount !== '';

    return hasAnswer || hasStepCount;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    // const isAllQuestionsAnswered = checkFirstItem(answers);
    // if (isAllQuestionsAnswered) {
      await submitAnswers();
    // } else {
    //   setError(CONSTANTS.MESSAGES.ATLEAST_SINGLE_FIELD);
    // }
  };

  const submitAnswers = async () => {
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: answers?.map(data => {
        return {
          question_id: data?.id,
          answer: data?.answer ? `${data?.answer}|${data?.stepCount}` : ''
        };
      })
    };

    try {
      setLoading(true);
      const response = await postAssessmentsQuestions(data);
      if (response?.data?.status === 200) {
        nextpage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSelectAnswer = (val, id) => {
    setError(null);
    let copiedData = [...answers];
    const index = copiedData.findIndex(obj => Number(obj.id) === Number(id));

    if (index !== -1) {
      // Deselect all answers
      copiedData = copiedData.map(obj => ({
        ...obj,
        stepCount: '',
        answer: obj?.answer ? obj?.answer : ''
      }));

      // Update the selected answer and all previous answers with the current date
      for (let i = 0; i <= index; i++) {
        copiedData[i].answer = copiedData[i].answer ? copiedData[i].answer : moment(new Date(Date.now()));
      }

      // Select the current answer
      copiedData[index].stepCount = val;
    }
    const filterAnswers = copiedData.map((data, i) => {
      if (Number(i) > Number(index)) {
        return {
          ...data,
          answer: ''
        };
      } else {
        return data;
      }
    });
    setAnswers([...filterAnswers]);
  };

  const getIsSelected = ids => {
    let copiedData = answers;
    const id = Number(ids.split(',')[0]);
    const index = copiedData.findIndex(obj => Number(obj.id) === Number(id));
    return answers[index]?.stepCount;
  };

  const getIsSelectedEnd = ids => {
    let copiedData = answers;
    const id = Number(ids.split(',')[1]);
    const index = copiedData.findIndex(obj => Number(obj.id) === Number(id));
    return answers[index]?.stepCount;
  };

  const disableDateHandler = ids => {
    const id1 = Number(ids?.split(',')[0]);
    const id2 = Number(ids?.split(',')[1]);
    const copiedData = [...answers];

    if (copiedData.length === 0) {
      return true;
    }

    // Find the minimum step index
    const minStepIndex = Math.min(id1, id2);

    // Check if any step before minStepIndex has a stepCount set
    const isPreviousStepSelected = () => {
      for (let i = 1; i < minStepIndex; i++) {
        if (copiedData.find(obj => obj.id === i && obj.stepCount)) {
          return false;
        }
      }
      return true;
    };

    return !isPreviousStepSelected();
  };

  const getMinimumDate = (index, value) => {
    let maxDate, minDate;
    if (index === 0) {
      if (maxDateArray[index + 1]) {
        maxDate = maxDateArray[index + 1] || null;
      } else {
        maxDate = moment(new Date());
      }
    } else if (index > 0 && index < 6) {
      minDate = maxDateArray[index - 1] ? moment(maxDateArray[index - 1]) : null;
      if (maxDateArray[index + 1]) {
        maxDate = maxDateArray[index + 1] || null;
      } else {
        maxDate = moment(new Date());
      }
    } else if (index === 6) {
      minDate = maxDateArray[index - 1] ? moment(maxDateArray[index - 1]) : null;
      maxDate = moment(new Date());
    }
    if (value === 'max') {
      return maxDate;
    }
    return minDate;
  };

  const getAnswerValue = ids => {
    let copiedData = answers;
    const id = Number(ids.split(',')[0]);
    const index = copiedData.findIndex(obj => Number(obj.id) === Number(id));
    return moment(answers[index]?.answer);
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    if (answers.length > 0) {
      let maxDateArrayTemp = [];
      answers.forEach((answer, index) => {
        if (index % 2 === 0) {
          if (answer.answer) {
            maxDateArrayTemp.push(moment(answer.answer));
          } else {
            maxDateArrayTemp.push('');
          }
        }
      });
      setMaxDateArray(maxDateArrayTemp);
    }
  }, [answers]);
  return (
    <>
      <CssBaseline />
      <Box mt={1} px={3} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: 'Continue',
              onClick: e => handleSubmit(e),
              type: 'submit'
            }
          ]}
          loading={loading}
        >
          <QuestionHeadings heading1={`ASSESSMENT ${page} OF ${totalPage}`} heading2="WHAT STEP ARE THEY ON TODAY?" heading3="UPDATE MONTHLY" />
          <Box my={1}>
            <Box display="flex" justifyContent="space-around" mb={2}>
              <Typography fontSize={12} variant="h6" color="lightgray">
                CLICK ONE
              </Typography>
              <Typography fontSize={12} variant="h6" color="lightgray">
                WHEN DID THEY TAKE A STEP?
              </Typography>
            </Box>
            {questions?.map((ques, index) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    paddingTop: 0,
                    marginBottom: '10px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      width: '30%',
                    }}
                  >
                    <div onClick={() => handleSelectAnswer(ques?.stepCount?.split(',')[0], ques?.id?.split(',')[0])} className={`tap-step-container ${getIsSelected(ques?.id) && 'tap-step-selected'}`}>
                      {ques?.stepCount?.split(',')[0]}
                    </div>
                    <ArrowForwardIcon />
                    <div onClick={() => handleSelectAnswer(ques?.stepCount?.split(',')[1], ques?.id?.split(',')[1])} className={`tap-step-container ${getIsSelectedEnd(ques?.id) && 'tap-step-selected'}`}>
                      {ques?.stepCount?.split(',')[1]}
                    </div>
                  </Box>
                  <Box sx={{width: '70%', paddingTop: 0}} key={index}>
                    <DatePicker
                      open={open[ques?.id?.split(',')[0]]}
                      setOpen={() =>
                        setOpen(prev => ({
                          ...prev,
                          [ques?.id?.split(',')[0]]: true
                        }))
                      }
                      onClose={() =>
                        setOpen(prev => ({
                          ...prev,
                          [ques?.id?.split(',')[0]]: false
                        }))
                      }
                      value={getAnswerValue(ques?.id)}
                      onChange={newValue => handleAnswerChange(ques?.id, newValue + 1)}
                      maxDate={getMinimumDate(index, 'max')}
                      minDate={getMinimumDate(index, 'min')}
                      disabled={disableDateHandler(ques.id, index)}
                      tooltip={CONSTANTS.ASSESSMENT_STEP_MESSAGES[ques?.id?.split(',')[0]]}
                      smallDatePickerClass="small-date-picker"
                    />
                    {/* {ques?.updated_by && <Typography pl={2}>{ques?.updated_by !== 'undefined' && getAnswerValue(ques?.id).isValid() ? `added by ${ques?.updated_by}` : ''}</Typography>} */}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Grid item xs={12} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default PageFour;
