import React from 'react';
import {Box, Select, MenuItem, Typography, FormControl} from '@mui/material';
import {CONSTANTS} from '../../constants';

const DropdownWithScore = ({questionId, label, options, answers, handleAnswerChange, setStepAnswer, stepAnswer}) => {
  const handleChange = event => {
    const selectedValue = event.target.value;
    handleAnswerChange(questionId, `${selectedValue}`);
  };

  const handleStepChange = event => {
    const selectedValue = event.target.value;
    setStepAnswer(prev => {
      return {
        ...prev,
        [questionId]: selectedValue
      };
    });
  };

  return (
    <>
      {questionId === 10 && (
        <Typography variant="body2" sx={{textAlign: 'right', paddingRight: '5%'}}>
          VS
        </Typography>
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} mb={2}>
        <FormControl sx={{minWidth: '80%'}}>
          <Typography textAlign="left" marginBottom={10} variant="body2">
            {label}
          </Typography>

          <Select
            labelId={`dropdown-label-${questionId}`}
            id={`dropdown-${questionId}`}
            value={answers[questionId]?.split('|')[0] || ''}
            onChange={handleChange}
            displayEmpty
            label={label}
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '200px',
                  overflowY: 'auto',
                  backgroundColor: '#d0090b',
                  width: '200px',
                  color: 'white'
                }
              }
            }}
          >
            <MenuItem disabled selected>
              Select the option
            </MenuItem>
            {options.map(option => (
              <MenuItem key={option.value} value={option.value} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box display="flex" flexDirection="column">
          {questionId === 9 ? (
            <Typography height="20px" variant="body2" marginBottom={10}>
              STEP
            </Typography>
          ) : (
            <Typography height="20px" variant="body2"></Typography>
          )}
          <Select
            labelId={`dropdown-label-${questionId}`}
            id={`dropdown-${questionId}`}
            value={stepAnswer[questionId]}
            onChange={handleStepChange}
            displayEmpty
            label={label}
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '200px',
                  overflowY: 'auto',
                  backgroundColor: '#CA0000'
                }
              }
            }}
          >
            {CONSTANTS.STEP_DROPDOWNS.map(option => (
              <MenuItem key={option.value} value={option.value} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </>
  );
};

export default DropdownWithScore;
