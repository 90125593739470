import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import theme from './styles/theme';
import {BrowserRouter as Router} from 'react-router-dom';
import ProjectRoutes from './routes/index';
import {styled} from '@mui/system';
import {Toaster} from 'react-hot-toast';
import './App.css';

function App() {
  const state = JSON.parse(localStorage.getItem('auth-storage'));
  const AppContainer = styled('div')(({theme}) => ({
    textAlign: 'center',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: state?.state?.user?.user_role === 'ADMIN' ? 'flex-start' : 'center',
    ...theme.palette.backgroundGradient
  }));

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContainer>
          <ProjectRoutes />
          {/* Toaster for toast message and triggered by the toast function in components */}
          <Toaster
            position="bottom-center"
            gutter={-110}
            containerStyle={{
              bottom: '18%',
              pointerEvents: 'none'
            }}
            toastOptions={{
              success: {
                iconTheme: {
                  primary: 'black'
                }
              },
              style: {
                background: '#000',
                color: '#FFF',
                pointerEvents: 'none'
              }
            }}
          />
        </AppContainer>
      </Router>
    </ThemeProvider>
  );
}

export default App;
