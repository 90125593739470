import React from 'react';
import sdlogo from '../../../images/sdlogo.png';
import {Box, Button, Typography} from '@mui/material';

const GetStartedFour = ({setPage}) => {
  return (
    <Box mx={3} my={5}>
      <img src={sdlogo} width="80" height="80" />
      <Typography mt={2} variant="h3" fontWeight="medium">
        Get Going Fast
      </Typography>
      <Typography variant="h3" fontWeight="medium">
        Let Us Handle the
      </Typography>
      <Typography variant="h3" fontWeight="medium">
        Heavy Lifting
      </Typography>
      <Typography mb={2} >HOW IT WORKS &bull; 4 OF 5</Typography>

      <ol style={{color: 'white', margin: '0 18px', marginBottom: "40px"}}>
        <li>
          <Typography variant="h6" textAlign="left" fontWeight="bold" pt={2}>
            Set up your group in five minutes.
          </Typography>
          <Typography textAlign="left" mb={1}>
            The app even sets up your reading plan.
          </Typography>
        </li>
        <li>
          <Typography variant="h6" textAlign="left" fontWeight="bold">
            Invite folks from within the app.
          </Typography>
          <Typography textAlign="left" mb={1}>
            Disciples are added to your group as they respond.
          </Typography>
        </li>
        <li>
          <Typography variant="h6" textAlign="left" fontWeight="bold">
            Know who you are discipling.
          </Typography>
          <Typography textAlign="left" mb={1}>
            Disciples' profiles are shared with you.
            <br/>
            Are they introverts or extroverts?
          </Typography>
        </li>
        <li>
          <Typography variant="h6" textAlign="left" fontWeight="bold">
            Get going with your group
          </Typography>
          <Typography textAlign="left" mb={1}>
            Home screens tell you and disciples what reading is coming up, when to order a resource. Disciples can even track their reading.
          </Typography>
        </li>
        <li>
          <Typography variant="h6" textAlign="left" fontWeight="bold">
            Track Disciples Progress
          </Typography>
          <Typography textAlign="left" mb={1}>
            In-app assessments help you know what to do and when to do it.
          </Typography>
        </li>
      </ol>

      <Button type="submit" fullWidth onClick={() => setPage(6)} variant="contained" color="secondary" style={{fontWeight: 400, marginBottom: '40px'}}>
        HOW DO YOU START?
      </Button>
    </Box>
  );
};

export default GetStartedFour;