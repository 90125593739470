import React, {useState, useEffect} from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import CssBaseline from '@mui/material/CssBaseline';
import {Box, Grid} from '@mui/material';
import Alert from '@mui/material/Alert';
import MultiLineTextInput from '../../common/MultiLineInput';
import {getAssessmentsQuestions, postAssessmentsQuestions} from '../../../api';
import {CONSTANTS} from '../../../constants';
import QuestionHeadings from './QuestionHeadings';

const PageFive = ({nextpage, user, page, totalPage}) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const placeHolderData = {
    20: 'Do they need a ramp?',
    21: 'Have you had a blink for them?'
  };
  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_4, user?.id, user?.group);
      if (response?.data?.status === 200) {
        setQuestions(response?.data?.data);
        const initialAnswers = response?.data?.data?.reduce((acc, curr) => {
          acc[curr.id] = curr?.answer || '';
          return acc;
        }, {});
        setAnswers(initialAnswers);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAnswerChange = (questionId, value) => {
    setError(null);
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isAllQuestionsAnswered = true;
    if (isAllQuestionsAnswered) {
      await submitAnswers();
    } else {
      setError(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED);
    }
  };

  const submitAnswers = async () => {
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: Object.entries(answers).map(([questionId, answer]) => ({
        question_id: questionId,
        answer: answer.trim()
      }))
    };
    try {
      setLoading(true);
      const response = await postAssessmentsQuestions(data);
      if (response?.data?.status === 200) {
        nextpage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);
  return (
    <>
      <CssBaseline />
      <Box mt={1} px={3} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: 'Continue',
              onClick: e => handleSubmit(e),
              type: 'submit'
            }
          ]}
          // headingText={`Assessment ${page} of ${totalPage}`}
          loading={loading}
        >
          <QuestionHeadings heading1={`ASSESSMENT ${page} OF ${totalPage}`} heading2="CUSTOMIZATIONS" heading3="UPDATE AS NEEDED" />
          <Box my={0}>
            {questions?.map((ques, index) => (
              <Box key={index} sx={{textAlign: 'left'}}>
                <MultiLineTextInput label={ques.question} placeholder={placeHolderData[ques.id]} value={answers[ques.id]} onChange={e => handleAnswerChange(ques.id, e.target.value)} autoComplete="off" margin="normal" multiline rows={2} />
              </Box>
            ))}
          </Box>
          <Grid item xs={12} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default PageFive;
